.pdf-v2 {
    /** This can be renamed if/when the current pdf styles are fully deprecated **/
    background-color: #fff;
    min-height: inherit;
    padding: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    div,
    p {
        line-height: normal;
    }

    /** Overrides for pre-existing styles **/
    #its-sidebar,
    #its-topbar,
    .its-sub-menu {
        display: none;
    }

    #its-content {
        background-color: #fff;
        box-shadow: none !important;
    }

    .its-weather-widget--title {
        color: rgba(0, 0, 0, 0.87);
    }

    .its-sidebar--padding {
        margin: 0;
    }

    .page-break {
        page-break-after: always;
    }

    .page-break-avoid {
        page-break-inside: avoid;
    }

    .not-ok {
        color: rgba(221, 44, 0, 0.75);

        md-icon {
            color: rgba(221, 44, 0, 0.75);
        }
    }

    .ok {
        color: rgb(70, 159, 8);

        md-icon {
            color: rgb(70, 159, 8);
        }
    }

    .its-record-pdf {
        display: block;

        &--cover-page {
            min-height: 800px;
            margin: auto;

            .logo {
                margin-bottom: 50px;

                img {
                    height: 150px;
                }
            }

            .program-name {
                margin-bottom: 10px;
                font-weight: 300;
                font-size: 30px;
                line-height: 30px;
                text-align: center;
            }

            .location-name {
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
                text-align: center;
            }

            .document-date {
                margin-bottom: 10px;
                font-size: 20px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.54);
            }

            .outcome {
                font-size: 24px;
                line-height: 24px;
            }
        }

        &--section {
            padding: 10px 0 10px 0;
            margin-left: 0;
            margin-right: 0;
            &-header {
                font-weight: 400;
                margin-bottom: 16px;
                margin-top: 5px;
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                padding: 10px 5px;
                border-radius: 10px;
                vertical-align: middle;

                md-icon {
                    margin: 0 8px 0 0;
                    color: black;
                }

                &-title {
                    span,
                    div {
                        font-size: 20px;
                        line-height: 20px;
                    }

                    span {
                        font-weight: 400;
                    }
                }

                .section-score {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: bold;
                }

                .logo {
                    margin-left: auto;

                    img {
                        max-height: 40px;
                    }
                }
            }

            &-sub-header {
                @extend .its-record-pdf--section-header;

                border-bottom: 1px solid black;

                &-title {
                    @extend .its-record-pdf--section-header-title;
                }
            }

            &-item {
                margin: 5px 0 0 0;
            }
        }

        &--question {
            clear: both;
        }

        &--audit-signature {
            .signature-block {
                padding: 5px;
                width: 90%;
                background-color: rgba(230, 230, 230, 0.54);

                img {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .signed-by {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }

            .signature-type {
                font-size: 20px;
                line-height: 20px;
                font-weight: 500px;
                margin-bottom: 10px;
            }
        }

        &--score-breakdown-table {
            margin-top: 16px;
            max-width: 100%;
            width: 100%;

            .header-row {
                padding: 5px;
                border-bottom: 1px solid lightgray;
            }

            .section-row {
                padding: 5px;

                .positive-change {
                    color: #03b04c;

                    md-icon {
                        color: #03b04c;
                    }
                }

                .negative-change {
                    color: #dd2c00;

                    md-icon {
                        color: #dd2c00;
                    }
                }
            }

            .section-row:nth-child(odd) {
                background-color: #fbfbfb;
                border-bottom: 1px solid rgb(243, 243, 243);
            }

            .section-row:nth-child(even) {
                background-color: #ffffff;
                border-bottom: 1px solid rgb(243, 243, 243);
            }

            .its-record--section--sps-positive-change {
                color: #03b04c;

                md-icon {
                    color: #03b04c;

                    @include media-breakpoint-down('xs') {
                        min-width: 15px;
                        width: 15px;
                        min-height: 15px;
                        height: 15px;
                        font-size: 15px;
                    }
                }
            }

            .its-record--section--sps-negative-change {
                color: #dd2c00;

                md-icon {
                    color: #dd2c00;
                }
            }
        }

        /**
         *  These are ported from the existing record styling for the sake of time
         *  Once time permits they can be gone through more thoroughly and removed/refactored/renamed
         **/
        .its-document--section--question {
            margin-top: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid rgb(203, 203, 203);
            .its-document--section--question--critical-issue,
            .its-document--section--question--followup-required,
            .its-document--section--question--tag {
                font-size: 12px;
                font-weight: 400;
                margin-top: 6px;
                padding-bottom: 5px;
                md-icon {
                    font-size: 18px;
                    height: 18px;
                    min-height: 18px;
                    width: 18px;
                    min-width: 18px;
                }

                .its-document--section--question--tag-text {
                    padding-left: 6px;
                }
            }

            .its-document--section--question--label {
                font-weight: 400;
                font-size: 13px;
                line-height: 13px;
                color: #4a4a4a;
            }

            .its-document--section--question-info {
                margin-bottom: 30px;
            }

            .its-document--section--question--responses {
                word-wrap: break-word;
                color: black;
                font-size: 13px;
                align-items: center;
            }

            .its-document--section--question--response-wrapper {
                position: relative;

                md-icon {
                    font-size: 13px;
                    line-height: 13px;
                    min-height: 13px;
                    height: 13px;
                    width: 13px;
                    min-width: 13px;
                }

                i {
                    padding-left: 2px;
                    color: #707070;
                    font-size: 13px;
                    line-height: 13px;
                    min-height: 13px;
                    height: 13px;
                    width: 13px;
                    min-width: 13px;
                }
            }

            .its-document--section--question--response-edit {
                position: absolute;
                left: -40px;
                cursor: pointer;
            }

            .its-document--section--question--response-text {
                white-space: pre-line;
                margin: 0;
                padding-left: 6px;
                align-self: center;
                line-height: 20px;
                font-family: Roboto, 'Helvetica Neue', sans-serif;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .its-document--section--question--response {
                padding-left: 6px;
                align-self: center;
                line-height: 13px;
                font-size: 13px;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .its-document--section-ranking-response-icon {
                color: #666666;
                background: #e0e0e0;
                height: 20px;
                width: 20px;
                text-align: center;
                font-size: 14px;
                border-radius: 3px;
                margin: 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .its-document--sentiment-positive {
                color: #33b679;
            }

            .its-document--sentiment-neutral {
                color: #e4c441;
            }

            .its-document--sentiment-negative {
                color: #d81b60;
            }

            .its-document--sentiment-container {
                padding: 8px;
                margin-bottom: 20px;
                margin-top: 12px;
                background-color: #f6f6f6;

                md-icon {
                    margin: 0;
                }

                .its-document--sentiment-container-label {
                    font-weight: 500;
                    color: #aaa;
                }
            }

            .its-document--sentiment-icon {
                font-size: 30px;
                margin-right: 12px;
                line-height: 30px;
                height: 30px;
                width: 30px;
            }

            .its-document--keyword-chip {
                border-radius: 16px;
                display: block;
                height: 32px;
                line-height: 32px;
                margin: 0 10px 20px 0;
                padding: 0 12px 0 12px;
                background: #f6f6f6;
                float: left;
                box-sizing: border-box;
                max-width: 100%;
                position: relative;
            }

            .its-document--sentiment-progress-bar-container {
                margin-top: 14px;
                margin-left: 12px;
            }

            .its-document--sentiment-progress-bar {
                background-color: #ccc;
                height: 8px;
                min-width: 250px;
            }

            .its-document--sentiment-progress-bar-negative,
            .its-document--sentiment-progress-bar-positive {
                width: 50%;
                height: 8px;
                float: left;
            }

            .its-document--sentiment-progress-bar-negative-pill {
                float: right;
                background-color: #d81b60;
                height: 8px;
            }

            .its-document--sentiment-progress-bar-positive-pill {
                float: left;
                background-color: #33b679;
                height: 8px;
            }

            .its-document--sentiment-progress-bar-score {
                text-align: right;
            }
        }

        .signoff-divider {
            margin: 16px 0;
        }

        .signoff-field {
            border-bottom: 1px solid black;
            min-height: 35px;
            margin: 5px 0;
        }

        .comment-and-media-container {
            display: inline-block;
            padding: 8px;
            margin: 5px 5px 5px 0;
            background-color: rgba(230, 230, 230, 0.54);
            font-size: 14px;
            line-height: 16px;

            .comment-container {
                margin-left: 8px;
            }

            .comment-icon {
                font-size: 16px;
                line-height: 16px;
                height: 14px;
                min-height: 14px;
                color: rgba(0, 0, 0, 0.75);
            }
        }

        .contact-custom-field {
            width: 50%;
            display: inline-block;
        }
    }
}

.its-record-pdf-modal--header-title {
    font-weight: 500;
    font-size: 1.2em;
}

.its-bold {
    font-weight: bold;
}

.its-question-header {
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.its-response-score {
    align-content: end;
}
