@import '~@fontsource/roboto/300';
@import '~@fontsource/roboto/400-italic';
@import '~@fontsource/roboto/400';
@import '~@fontsource/roboto/500';
@import '~@fontsource/roboto/700';
@import '~froala-editor/css/froala_editor.pkgd.min';
@import '~tributejs/dist/tribute.css';
@import '~material-design-icons/iconfont/material-icons';
@import '~angular-material/angular-material';

@import '~@intouch/essential-styles/source/config';
@import '~@intouch/its.essential/sass/config';
@import 'config';
@import '~@intouch/essential-styles/source/main';
@import '~@intouch/its.essential/sass/main';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import 'layout/its.form';
@import 'layout/its.tabs';
@import 'layout/its.topbar';
@import 'layout/its.submenu';
@import 'layout/its.layout';
@import 'layout/its.product-filter';
@import 'layout/its.modal';

@import 'modules/its.advanced.search';
@import 'modules/its.login';
@import 'modules/its.main';
@import 'modules/its.charts';
@import 'modules/its.loading';
@import 'modules/its.hierarchy.panel';
@import 'modules/its.alert.message';
@import 'modules/its.table.chart';
@import 'modules/its.table.list';
@import 'modules/its.hierarchy.breadcrumb';
@import 'modules/its.document';
@import 'modules/its.event.list';
@import 'modules/its.table.data';
@import 'modules/its.data-table';
@import 'modules/its.numerical.chart';
@import 'modules/its.filters';
@import 'modules/its.settings';
@import 'modules/its.social';
@import 'modules/its.customer-rescues';
@import 'modules/its.button.group';
@import 'modules/its.global.filters';
@import 'modules/its.dashboard';
@import 'layout/its.print';
@import 'modules/its.card';
@import 'modals/its.attachment.modal';
@import 'modals/its.pdf.modal.scss';
@import 'layout/its.pdf-record.scss';
@import 'layout/its.pdf-record-v2.scss';
@import 'modules/its.custom.chart';
@import 'modules/its.custom.chart.editor';
@import 'layout/its.pdf-record-summary';
@import 'modules/its.md.color.picker';
@import 'modules/its.journey-maps';
@import 'modules/its.action-campaigns';
@import 'modules/its.media-gallery';
@import 'modules/its.global';
@import 'panels/its.filter.panel';
@import 'panels/its.icon.picker.panel';
@import 'panels/its.comment.panel';
@import 'modules/its.iql.global.filters';
@import 'modules/its.kpis';
@import 'modules/its.record';
@import 'modules/its.scheduled.reports';
@import 'modules/its.custom.report';
@import 'modules/its.filter.data.group.set';
@import 'modules/its.comments.dashboard';
@import 'modules/its.hierarchy.comparison.dashboard';
@import 'modules/its.trigger';

@import 'modules/standard-dashboards/its.standard.dashboard';
@import 'modules/standard-dashboards/its.dashboard.check';
@import 'modules/standard-dashboards/its.dashboard.survey';
@import 'modules/standard-dashboards/its.dashboard.shop';
@import 'modules/standard-dashboards/its.dashboard.audit';
@import 'modules/standard-dashboards/its.hierarchy.node.tree.item';

@import 'components/iti.nav-list';

@import 'panels/its.global.filter.panel';

@import 'modules/its.integrations';

@import 'theme/its.table';
@import 'theme/its.highlights';
@import 'theme/its.print';

@import 'modules/its.misc';
@import 'overrides';
@import '~shepherd.js/dist/css/shepherd.css';
@import 'shepherd.overrides';
@import 'theme/its.highcharts';
