.its-customer-rescue--wrapper {
    display: flex;
    flex-direction: column;

    // override the document classes when showing a record on an action view
    .its-document--toolbar {
        display: none;
    }

    .its-document .its-document--content {
        box-shadow: none;
        padding: 0;
        margin: 0;
    }

    .its-document--header {
        padding-top: 5px;
    }

    @media (min-width: 960px) {
        flex-direction: row;
    }
}

.its-customer-rescue--create-modal-scroll-override {
    overflow-x: hidden;
}

.its-customer-rescue--section-content {
    .its-customer-rescue--properties-container {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.its-customer-rescues--tab-text {
    color: #3f9ecc;
    font-weight: 500;
    margin: auto auto auto 24px;
    text-transform: uppercase;
}

.its-customer-rescues--select-container {
    margin-right: 12px;
    margin-left: 10px;
}

.its-customer-rescue--action {
    width: 100%;
}

.its-customer-rescue--record {
    width: 100%;
}

.its-customer-rescue--record__visible {
    display: block;
}

.its-customer-rescue--record-actions {
    text-align: right;
    margin-bottom: 20px;
}

.its-customer-rescue--record-close {
    cursor: pointer;
}

.its-customer-rescue--section {
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }
}

.its-customer-rescue-card {
    padding: 15px;
    background: white;
}

.its-customer-rescue--section-title {
    font-weight: bold;
    font-size: 20px;
    margin: 14px auto 0 10px;
}

.its-customer-rescue--properties {
    padding-bottom: 20px;

    .its-customer-rescue--properties-container {
        margin: 0 10px;
    }
}

.its-customer-rescue--property-value {
    md-input-container {
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: -5px;
    }

    .md-icon-button + .md-datepicker-input-container {
        margin-left: 0;
        display: block;
        margin-right: 4px;
    }

    .md-select-value {
        padding-left: 12px;
    }

    .md-button.md-icon-button {
        float: left;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-bottom: 0;
    }

    .md-datepicker-triangle-button.md-button.md-icon-button {
        margin-right: 8px;
    }

    .md-datepicker-input {
        padding-left: 10px;
        padding-bottom: 5px;
    }
}

.its-customer-rescue--property-value-search {
    md-select {
        span:first-child {
            padding-left: 45px;
        }
    }
}

.its-customer-rescue--property-progress {
    display: inline;
}

.its-customer-rescue--assignee-select .md-select-value.md-select-placeholder {
    color: inherit;
}

.its-customer-rescue--hover-icon-down,
.its-customer-rescue--hover-icon-up {
    margin-top: 4px;
    margin-left: -2px;
    height: 18px;
    width: 18px;
    font-size: 18px;
}

.its-customer-rescue--tag-chip {
    background: rgb(224, 224, 224);
    color: rgb(66, 66, 66);
    border-radius: 16px;
    height: 32px;
    line-height: 32px;
    padding: 0px 12px;
    margin: 8px 8px 0 0;
    font-size: 16px;

    .its-customer-rescue--tag-chip-icon {
        cursor: pointer;
    }

    md-icon {
        margin-bottom: 2px;
    }
}

.customer-rescue--no-margin-dropdown {
    md-input-container {
        margin: 0;
    }
}

.customer-rescue--info-panel {
    margin-top: 10px;
    background-color: #f6f6f6;
    padding: 12px;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.customer-rescue--assignee-chip {
    background: rgb(224, 224, 224);
    color: rgb(66, 66, 66);
    border-radius: 16px;
    height: 32px;
    line-height: 32px;
    padding: 0px 12px;
    margin: 8px 8px 0 0;
    font-size: 16px;

    .customer-rescue--assignee-chip-icon {
        cursor: pointer;
    }
    md-icon {
        margin-bottom: 2px;
    }
}

.customer-rescue--linked-listing-select {
    margin-top: 0;
    margin-bottom: 0;

    md-select-value {
        border-bottom: none;
    }
}
