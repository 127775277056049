.its-globalfilter--panel {
    border: solid 1px #c8c4c8;
}

.its-globalfilter-panel--margin {
    padding-top: 110px;
}

.its-globalfilter-panel--iconhover {
    cursor: pointer;
}

.its-toggleicon {
    cursor: pointer;
    padding: 25px;
}

.its-icon-padding {
    padding: 20px;
}

.its-select-margin {
    margin: 12px 0px 26px 0px;
}

.its-tag-title {
    padding: 5px 2px;
    margin-top: -4px;
}

.its-globalfilter--panel--reset {
    height: 60px;
}
