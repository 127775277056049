.its-social--ui-view {
    padding: 0 12px 12px;
}

.its-social--box {
    background: white;
    border-radius: 3px;
}

.its-social--star {
    height: 24px;
    width: 24px;
}

.its-social--empty-state-container {
    margin: 100px 0;
}

@include media-breakpoint-up(md) {
    @include media-breakpoint-down(md) {
        .its-social-parent-container {
            &.side-bar-open {
                .its-social--total-reviews-container {
                    margin-right: 0;
                }

                iti-star-ratings-list-real {
                    flex: 1 1 100%;
                    max-width: 100%;
                    max-height: 100%;
                    box-sizing: border-box;
                    margin-top: 12px;
                }
            }
        }
    }
}

.its-social--load-more-text {
    font-size: 18px;
    margin: 18px 0 0 89px;
    color: $primaryIntelligenceColour;
    &:hover {
        cursor: pointer;
    }
}

.its-social--empty-state-icon {
    font-size: 56px;
    height: 56px;
    width: 56px;
    margin-bottom: 15px;
}

.its-social--empty-state-text {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
}

.its-social--star-colored {
    color: #f8e71c;
    margin: 0;
}

.its-social--star-grey {
    color: #ddd;
}

.its-social--total-reviews-container {
    margin: 0 12px;

    @include media-breakpoint-down(sm) {
        margin: 12px;
    }
}

.its-social--ratings-container {
    min-height: 265px !important;

    @include media-breakpoint-down(md) {
        min-height: 265px !important;
        width: 100%;
    }

    .its-social--ratings-empty-state {
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .its-social--ratings-star-container {
        margin: 10px 0 25px;
    }

    .its-social--ratings-large-number {
        font-weight: 400;
        font-size: 75px;
        line-height: 85px;
        margin: 0;
    }

    .its-social--ratings-text {
        margin: 0;
        font-size: 22px;
        line-height: 22px;
    }

    .its-social--total-reviews-text {
        margin: 0 0 60px;
        font-size: 22px;
        line-height: 22px;
    }

    .its-social--ratings-subtext {
        margin: 0;
        font-size: 13px;
        line-height: 13px;
        color: #8e8e8e;
    }

    .its-social--empty-text-margin {
        margin: -25px 0 60px;
    }
}

.its-social--wordcloud-container {
    height: 350px;
    margin: 12px 0;
}

.its-social--reviews-container {
    padding: 30px;

    @include media-breakpoint-down(md) {
        margin-bottom: 12px;
    }

    .its-social--reviews-star-container {
        margin: 6px 0 8px;
    }

    .its-social--review-header {
        margin: 0;
        font-size: 18px;
        line-height: 18px;
        font-weight: 500;
    }

    .its-social--review-container {
        margin-top: 40px;
        display: flex;

        a {
            color: rgb(0, 163, 224);
        }

        .its-social--review-link-disabled {
            color: #454545;
            &:hover {
                cursor: text;
                text-decoration: none;
            }
        }

        .its-social--review-title {
            font-weight: 500;
            font-size: 16px;
            margin: 10px 0;
        }

        .its-social--review-content {
            p {
                margin-top: 0;
                line-height: 22px;
                margin-bottom: 10px;
            }
        }

        .its-social--review-timestamp {
            color: #757575;
            font-size: 12px;
        }
    }
}

.its-social--review-icon {
    max-width: 50px;
    height: 50px;
    margin-right: 40px;
    border-radius: 3px;
}

.its-social--top-reviews-container {
    margin-left: 12px;

    @include media-breakpoint-down(md) {
        margin-left: 0;
    }
}

.its-social--pie-container {
    min-height: 50px;
}

.its-social--star-list--row-margin {
    height: 24px;
    margin-bottom: 6px;
}

.its-social--star-list-bar {
    height: 12px;
    background: #20aee3;
    margin-left: 20px;
    margin-right: 8px;
}

.its-social--mentions-title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
}

.its-social--mention-sub-text {
    margin: 10px 0;
    font-size: 13px;
    line-height: 13px;
    color: #8e8e8e;
}

.its-social--mentions-content-text {
    margin: 20px 0 0;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
}

.its-social--mentions-link {
    font-weight: 400;
    &:hover {
        text-decoration: none;
    }
}

.its-social--mention-sentiment-box {
    height: 24px;
    width: 24px;
    border: 1px rgba(0, 0, 0, 0.25) solid;
}

.its-social--mention-sentiment-text {
    margin: 0 0 0 15px;
    font-weight: 500;
    font-size: 12px;
}

.its-social--mention-content-text {
    line-height: 1.4;
}

.its-social--mention-load-more {
    color: #00a3e0;
    font-size: 1.3em;
    cursor: pointer;
    margin: 0 0 20px 95px;
}

// LOCATION SIDENAV CLASSES
.its-social--sidebar-container {
    min-width: 100%;
    background: white;
    z-index: 2;
    border-right: 1px solid #ccc;

    @include media-breakpoint-up(sm) {
        min-width: 275px;
    }

    .its-social--sidebar-close {
        margin-left: auto;
        padding-right: 0;
        margin-right: 0;
    }

    .its-social--sidebar-location-icon {
        margin: 0 12px 0 0;
    }

    .its-social--sidebar-search-container {
        margin: 12px 4px 0 24px;
    }

    .its-social--sidebar-link {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
    }

    .its-social--sidebar-title {
        padding: 18px 25px 15px;
        color: #212121;
        min-height: 55px;
        max-height: 55px;
    }

    .its-social--sidebar-locations-list {
        background: white;
    }

    .its-social--sidebar-location-container {
        padding: 12px 12px 12px 24px;
        transition: all ease-in-out 150ms;
        &:hover {
            cursor: pointer;
            color: $primaryIntelligenceColour;
            background: #f9f9f9;
            padding-left: 36px;
        }
    }

    .its-social--sidebar-selected-location {
        padding-left: 36px;
        background: #f9f9f9;
        color: $primaryIntelligenceColour;
    }
}

.its-social--topbar-icon-active {
    md-icon {
        color: $primaryIntelligenceColour !important;
    }
}

// SENTIMENT COLOUR CLASSES

.its-sentiment-text-green {
    color: #76b543;
}

.its-sentiment-text-yellow {
    color: #fbce33;
}

.its-sentiment-text-red {
    color: #eb5f48;
}

.its-sentiment-box-grey {
    background: #e0e0e0;
    border-right: none;
    &:last-of-type {
        border-right: 1px rgba(0, 0, 0, 0.25) solid;
    }
}

.its-sentiment-box-green {
    background: #76b543;
    border-right: none;
    &:last-of-type {
        border-right: 1px rgba(0, 0, 0, 0.25) solid;
    }
}

.its-sentiment-box-yellow {
    background: #fbce33;
    border-right: none;
    &:last-of-type {
        border-right: 1px rgba(0, 0, 0, 0.25) solid;
    }
}

.its-sentiment-box-red {
    background: #eb5f48;
    border-right: none;
    &:last-of-type {
        border-right: 1px rgba(0, 0, 0, 0.25) solid;
    }
}

.its-sentiment-box-no-border-left {
    border-left: none;
}

.its-social--mentions-page-container {
    overflow-x: scroll;
}

.its-social--mention-column {
    min-width: 450px;

    @include media-breakpoint-down(xs) {
        min-width: calc(100% - 12px);
    }
}

.its-social--mentions-container {
    margin: 6px 12px 12px 0;
    background-color: white !important;
    border-radius: 3px;

    .its-social--reviews-star-container {
        margin: 6px 0 8px;
    }

    .its-social--review-header {
        margin: 12px 0 12px 30px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 500;
    }

    .its-social--mention-container {
        margin-top: 20px;
        padding: 12px 30px;

        a {
            color: rgb(0, 163, 224);
        }

        .its-social--mention-link-disabled {
            color: #454545;
            &:hover {
                cursor: text;
                text-decoration: none;
            }
        }

        .its-social--mention-icon {
            width: 50px;
            height: 50px;
            margin: 0 15px 0 0;
            font-size: 48px;
            color: $primaryIntelligenceColour;
            border-radius: 3px;
        }

        .its-social--mention-title {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 1.5;
        }

        .its-social--mention-content {
            line-height: 22px;
            margin-bottom: 10px;
        }

        .its-social--mention-timestamp {
            color: #757575;
            font-size: 12px;
        }
    }
}
