iti-nav-list-container {
    .its-scroll-y {
        &::-webkit-scrollbar {
            width: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-400;
            border-radius: 4px;
        }
    }
}

iti-nav-list-item {
    label {
        cursor: pointer;
    }

    div {
        padding: $Padding_half $Padding $Padding_half 18px;
    }

    iti-nav-list-item {
        div {
            padding-left: 26px;
        }
    }

    iti-nav-list-item-menu {
        display: none;
    }

    div:first-of-type:hover iti-nav-list-item-menu {
        display: block;
    }
}

iti-nav-list-empty-state {
    label {
        color: $color-grey-500;
        cursor: default;
    }
}
