.its-table-data {
    padding: 20px 20px 20px 20px;
    margin: 10px;
    border-radius: 3px;

    .its-table-data--percentage-bar-container {
        background-color: lightgrey;
        width: 100%;

        .its-table-data--percentage-bar-container--fill {
            background-color: rgb(149, 206, 255);
        }
    }

    // move stuff below to _its.records.scss

    .its-records-header {
        margin-bottom: 20px;
        line-height: 2;

        .its-records-header--secondary {
            line-height: 1;
        }
    }

    .its-records-question-container {
        margin: 0;

        md-list-item {
            min-height: 30px;
        }

        .its-table {
            span {
                padding: 0 10px;
                text-overflow: clip;
                white-space: normal;
            }
        }
    }

    .its-table--row {
        .its-table--row__container {
            border-bottom: 1px solid #efefef;
            padding-bottom: 15px;
        }
    }
}
