.its-data-table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;

    .its-data-table--header-cell {
        padding: 20px;
        white-space: nowrap;
        text-align: left;
    }

    .its-data-table--row {
        border-top: 1px solid #f6f6f6;
    }

    .its-data-table--row-totals {
        border-top: 1px solid #ddd;
    }

    .its-data-table--cell {
        padding: 20px;
    }

    .its-data-table--column-header {
        white-space: nowrap;
    }

    .its-data-table--column-totals {
        border-left: 1px solid #ddd;
    }

    .its-data-table--important {
        font-weight: 500;
    }
}

.its-data-table-container {
    padding: 20px;
    background-color: #fff;
    margin: 10px 10px 20px;

    .its-data-table-container--title {
        text-align: center;
    }

    .its-data-table-container--table {
        margin-top: 20px;
        overflow-x: scroll;
        margin-bottom: 20px;
    }
}

.its-data-table-container__device-report {
    .its-data-table-container {
        margin-top: 0;
    }
}
