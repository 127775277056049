iti-sub-nav md-toolbar.its-sub-menu {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 8px 0;
    min-height: 57px;
    max-height: initial;

    .md-toolbar-tools {
        height: auto;
        max-height: initial;
    }
}

.its-md-switch {
    font-size: 0.8em;
    margin-top: -6px;

    md-switch {
        margin-top: 25px;

        span {
            color: rgba(0, 0, 0, 0.38);
        }
    }
}

.its-with-sub-nav {
    margin-top: 65px;
}

a {
    text-decoration: none;
    font-weight: 500;
    color: $iqProductColor;

    &:hover {
        text-decoration: underline;
    }
}

.its-accordion .its-accordion-list-item .its-accordion-list-item--label {
    &.highlighted {
        color: $iqProductColor;

        .its-accordion-list-item--label--icon {
            color: $iqProductColor;
        }
    }

    &:hover {
        color: $iqProductColor;

        .its-accordion-list-item--label--icon {
            color: $iqProductColor;
        }
    }

    &.disabled {
        .its-accordion-list-item--label--text {
            opacity: 0.5;
        }
    }
}

.iti-sub-title-row {
    margin: 15px;
}

.bg-white {
    background: white;
}

.its-condense-top {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.its-condense-bottom {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.its-empty-state--text {
    font-size: 1em;
    font-weight: 400;
}

.its-primary-color-bg {
    background-color: $IntelligenceColour_Primary;
}
.its-product-white-bg {
    background-color: $IntelligenceColour_White;
}

.its-content-nav-footer {
    border-top: 1px solid $BorderColour;
    height: 40px;
    background: white;
    padding-right: 10px;
    padding-left: 10px;

    md-icon {
        @extend .its-clickable !optional;
    }
}
