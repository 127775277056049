.its-filters-select {
    margin: 12px;

    > md-input-container {
        margin: 0;
        margin-right: 14px;
    }
}

.its-filters-select--capture {
    .its-filters-select {
        margin: 0 12px 0;
    }
}

.its-filters-datepicker {
    max-height: 36px !important;

    md-datepicker {
        height: 34px;

        &._md-datepicker-has-triangle-icon {
            padding: 0 12px;
        }

        .md-datepicker-button {
            display: none;
            top: 0;
        }

        .md-datepicker-input-container {
            input.md-datepicker-input {
                height: 34px;
            }
        }

        .md-button.md-icon-button.md-datepicker-triangle-button {
            bottom: 0px;
        }

        input {
            min-width: 80px;
        }
    }

    .its-filters-datepicker--label {
        padding: 0 10px;
    }

    .md-button {
        min-width: 0;
        min-height: 0;
        line-height: 1.5;

        &:focus {
            background: none;
        }
    }

    .its-filters-datepicker--icon {
        .md-button {
            min-width: 0;
            min-height: 0;
            margin: 0 0 0 0;
            padding: 0;
        }
    }

    .material-icons {
        line-height: 1.5;
    }
}

.its-tag-filterpanel {
    margin-top: 112px;
    border-top: 1px solid #eee;
}

.its-sidenav-right {
    position: fixed;

    .its-alert-message .its-alert-message--icon {
        font-size: 2em;
        width: 100%;
    }

    .its-alert-message .its-alert-message--text {
        margin-top: 20px;
        font-style: italic;
    }
}

.its-tags-wrapper {
    padding: 10px;
}

.its-tag-title {
    margin-top: 2px;
    padding: 2px;
}

.its-sidenav-spiner {
    margin-top: 400px;
}

.its-sticky--tag-title {
    justify-content: space-between;
}

.its-sticky--tag-title-icon {
    padding: 2px;
}

.its-sticky--tag-title-back {
    cursor: pointer;
}

.its-sticky--tag-title-text {
    padding-top: 15px;
}

.its-sticky--tag-title-flexwrap {
    width: 100px;
}

.its-search-wrapper {
    padding: 15px 15px 0 15px;
}

.its-tagfilter--badge {
    position: relative;
    top: -25px;
    right: -15px;
    font-size: 0.7em;
    background: rgb(63, 158, 204);
    color: white;
    width: 14px;
    height: 14px;
    line-height: 12px;
    border-radius: 50%;
}

.its-input--container {
    top: 5px;
}

.its-reset--tags {
    padding-top: 25px;
}

.its-chip-cancel {
    cursor: pointer;
}

.its-tags-select--onback {
    cursor: pointer;
}

.its-match-label {
    margin-top: 23px;
    margin-left: 60px;
}

.its-dropdown-container {
    .md-select-value {
        min-height: 0px;
    }
}

.its-match-label--disabled {
    margin-top: 23px;
    margin-left: 40px;
}

.its-match-label--select-tab {
    margin-top: 28px;
    margin-left: 90px;
    padding-right: 20px;
}

@media (max-width: 960px) {
    .its-filters-select {
    }

    .its-filters-forms {
    }
    .its-sidenav-spiner {
        margin-top: 250px;
    }
}

@media (max-width: 600px) {
    .its-filters-select {
        md-select {
            margin: 0;
        }
    }
}

.its-filter-operator-group {
    .its-filter-operator-group--operator {
        margin-bottom: 0;

        &.its-not-selected {
            margin-bottom: $Margin;
        }
    }

    .its-filter-operator-group--form {
        margin-left: $Margin;

        md-input-container {
            margin-top: $Margin;
            margin-bottom: $Margin;

            label {
                display: none;
            }

            &.its-width--full {
                md-datepicker {
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    display: -ms-flexbox;
                    min-width: 0;

                    .md-datepicker-input-container {
                        -ms-flex: 1;
                        flex: 1;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
