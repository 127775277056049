.its-table-chart-card {
    padding: 20px;
}

.its-table-chart-container {
    margin-top: 30px;

    .its-table-chart--data-table {
        max-height: 350px;
        overflow: scroll;
        table {
            margin-bottom: 50px;
        }
    }

    .its-table-chart--head-row {
        th {
            text-align: center;
            color: #ccc;
            font-weight: 500;
            padding-right: 10px;
        }
    }

    .its-table-chart--body-row {
        border-bottom: 1px solid #eee;
        td {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 10px;
            vertical-align: middle;
            text-align: center;
        }
    }
}
