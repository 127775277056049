.its-scheduled-report--filters--chip {
    margin: 5px;
    border-radius: 15px;
    padding: 2px 10px 2px 10px;
    max-width: 280px;
    color: white;
    background-color: rgb(0, 163, 224);
    width: max-content;
}

.its-subscription-modal {
    margin: 0 20px;

    .its-subscription-modal--title {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.75em;
        padding-left: 3px;
    }

    md-select[disabled] .md-select-value {
        color: black;
    }

    .md-input[disabled] {
        color: black;
    }
}

.its-subscription-modal--dashboard-hint {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 30px;
}

.its-subscription-modal--dashboard-large-icon {
    font-size: 100px;
    width: 120px;
    height: 100px;
    color: rgba(0, 0, 0, 0.15);
    margin: 0;
}

.its-subscription-modal--dashboard-radio {
    margin-bottom: 0;
    margin-top: 10px;
}

.its-subscription-modal--small-title {
    color: rgba(0, 0, 0, 0.54);
    font-size: 11px;
    line-height: 11px;
    padding-left: 2px;
}

.its-subscription-modal--layout-type-text {
    padding-left: 4px;
    color: black;
}
