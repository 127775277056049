.its-hierarchy-breadcrumb-list {
    list-style: none;
    padding-left: 10px;

    .its-hierarchy-breadcrumb-list--list-item {
        float: left;
        font-size: 0.75em;
        font-weight: 500;
        color: black;
        opacity: 0.8;

        .its-hierarchy-breadcrumb-list--list-item--not-last {
            color: black;
            opacity: 0.3;
            &:hover {
                opacity: 0.6;
            }
        }

        .its-hierarchy-breadcrumb-list--list-item--last {
            padding: 3px 3px 3px 0;
        }

        .its-hierarchy-breadcrumb-list--list-item--only-one {
            margin-top: -5px !important;
        }

        .its-hierarchy-breadcrumb-list--list-item {
            margin-right: 8px;
        }
    }
}
