// Score Per Section Classes
.its-condensed-record > md-content {
    width: unset;
    box-shadow: none;
}

.its-record--section {
    margin: 0 $Margin $Margin;
    background: $White;
    border-radius: $BorderRadius;
    padding: $Padding;

    @include media-breakpoint-down('sm') {
        margin: 0 0 $Margin;
        border-radius: 0;
    }

    .its-record--section-title {
        margin-left: 40px;

        @include media-breakpoint-down('sm') {
            margin-left: 20px;
        }
    }

    .its-record--section--sps-column-label {
        @extend .its-text--caption;
        @extend .its-text__uppercase;
        align-self: center;
        margin-bottom: 12px;
    }

    .its-record--section--sps-row-label {
        margin-left: 80px;
        min-height: 24px;

        @include media-breakpoint-down('xs') {
            margin-left: 20px;
        }
    }

    .its-record--section--sps-row {
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .its-record--section--sps-empty-cell {
        margin-right: 12px;
        margin-left: 9px;
    }

    .its-record--section--sps-change-cell-label {
        color: $FontColour_Light;
    }

    .its-record--section--sps-positive-change {
        color: $PositiveColour;

        md-icon {
            color: $PositiveColour;

            @include media-breakpoint-down('xs') {
                @include md-icon-size(15px);
            }
        }
    }

    .its-record--section--sps-negative-change {
        color: $DangerColour;

        md-icon {
            color: $DangerColour;
        }
    }
}

//Sidebar classes
.its-record--sidebar {
    background: white;
    width: 380px;
    z-index: 1;
    border-right: 1px solid #ccc;

    @include media-breakpoint-down('sm') {
        width: unset;
        margin-bottom: 20px;
    }

    .its-record--sidebar-section {
        padding: 35px 30px;
        max-width: 400px;

        @include media-breakpoint-down('sm') {
            margin: auto;
            padding: 20px;
        }

        md-list-item {
            border-bottom: 1px solid #dddddd;
            padding-left: 0;
            & > div.md-button {
                padding-left: 30px;
            }
        }

        .its-record--sidebar--list-headers {
            @extend .its-text--caption;
            @extend .its-text__uppercase;
            padding-left: 30px;
        }

        .its-record--sidebar-score-sub-info {
            margin-top: 20px;
        }

        .its-record--sidebar-title {
            &:not(:first-of-type) {
                margin-top: 28px;
            }
        }

        .its-record--sidebar-sub-title {
            @extend .its-text__light;
        }

        .its-record--sidebar-info-container {
            margin: 20px 0 0 0;
        }

        .its-record--sidebar-product-info-container {
            margin-top: 20px;

            @include media-breakpoint-down('sm') {
                margin-top: 0;
            }
        }

        .its-record--sidebar-icon {
            @include md-icon-size(16px);
            @extend .its-text__light;
            margin: 0 12px 0 0;
        }

        // Scoring Classes

        .its-record--sidebar-followup-container {
            padding: 4px 0;
            min-height: 26px;
        }

        .its-record--sidebar-icon-followup {
            @include md-icon-size(18px);
            color: $DangerColour;
            margin: 0 0 0 3px;
        }

        .its-record--sidebar-score-large {
            @extend .its-text--headline;
        }

        .its-record--sidebar-score-large-padding {
            padding-bottom: 20px;
        }

        .its-record--sidebar-score--edit-button {
            cursor: pointer;
        }

        .its-record--sidebar-hierarchy--edit-button {
            cursor: pointer;
            margin-right: 20px;
        }

        // Sentiment Classes
        .its-record--sidebar-sentiment-container {
            md-icon {
                margin: 0;
                @include md-icon-size(32px);
            }

            .its-record--sidebar-sentiment-bar-container {
                margin-left: auto;
            }

            .its-record--sidebar-sentiment-bar-bg {
                background-color: #ccc;
                height: 8px;
                min-width: 200px;
                margin: 12px 0 3px;
            }

            .its-record--sentiment-bar-score {
                @extend .its-text--caption;
                text-align: right;
            }

            .its-record--sentiment-progress-bar-negative,
            .its-record--sentiment-progress-bar-positive {
                width: 50%;
                height: 8px;
                float: left;
            }

            .its-record--sentiment-progress-bar-negative-pill {
                float: right;
                background-color: #d81b60;
                height: 8px;
            }

            .its-record--sentiment-progress-bar-positive-pill {
                float: left;
                background-color: #33b679;
                height: 8px;
            }
        }
    }

    .its-record--sidebar-location-section {
        @include media-breakpoint-down('sm') {
            max-width: 550px;
        }
    }

    .its-record--sidebar-weather-section {
        padding-bottom: 0;
    }
}

//PRINT CSS

@media print {
    .its-record--sidebar {
        box-shadow: none;
        width: unset;
    }

    .its-record--sidebar-print {
        display: unset;
    }

    .its-record--section {
        box-shadow: none;
    }
}

@media screen {
    .its-record--sidebar-print {
        display: none;
    }
}
