.its-custom-chart-container {
    @extend .its-margin__half;
    position: relative;

    &:hover {
        .its-custom-chart--chart-toolbar {
            visibility: visible;
            z-index: 1;
        }
    }

    .its-custom-chart--chart-toolbar {
        position: absolute;
        width: 100%;
        top: 6px;
        left: 0;
        height: 40px;
        background-color: transparent;
        visibility: hidden;

        md-menu {
            padding-right: 6px;
        }
    }
}

.its-case-management-chart-container {
    position: relative;

    &:hover {
        .its-case-management-chart--chart-toolbar {
            visibility: visible;
            z-index: 1;
        }
    }

    .its-case-management-chart--chart-toolbar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 40px;
        margin-top: 5px;
        // background-color: #FFFFFF;
        visibility: hidden;

        md-menu {
            padding-right: 10px;
        }
    }
}

.its-custom-chart {
    @extend .its-rounded;
    padding: 20px;
    min-height: 410px;
    height: 410px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid $BorderColour;

    .its-custom-chart--title {
        text-align: center;
        .its-custom-chart--header {
            text-align: center;
            padding-top: 5px;
        }

        .its-custom-chart--sub-header {
            font-weight: 400;
            padding-top: 3px;
            font-size: 0.95em;
            color: #aaa;
        }
    }

    .its-custom-chart--content {
        margin-top: 20px;

        .its-custom-chart--content--chart {
            width: 100%;

            &.its-custom-chart--hidden {
                height: 0;
                display: none;
            }

            &.its-custom-chart--visible {
            }
        }

        .its-custom-chart--loading-state {
            align-items: center;

            iti-chart-loading {
                margin-top: 0;

                .its-chart--loader {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }

        .its-custom-chart--empty-state {
            align-items: center;

            iti-alert-message {
                margin-top: 0;
            }
        }

        .its-custom-chart--content--image {
            img {
                display: block;
                max-width: 100%;
                max-height: 310px;
                width: auto;
                height: auto;
            }

            height: 310px;
        }

        .its-custom-chart--content--data-table--container {
            max-height: 100%;

            overflow: auto;
            padding: 10px;

            md-list {
                width: 100%;
                max-height: inherit;

                md-list-item {
                    min-height: 38px;
                }
            }

            .its-custom-chart--content--data-table-header-row {
                font-weight: 400;
                padding-top: 3px;
                font-size: 0.95em;
                color: #aaa;

                &.transposed {
                    span {
                        min-width: 100px;
                        margin-left: 10px;
                        overflow-wrap: normal;
                    }
                }
            }

            .its-custom-chart--content--data-table-data-row {
                font-weight: 400;

                &.transposed {
                    span {
                        min-width: 100px;
                        margin-left: 10px;
                        overflow-wrap: normal;
                    }
                }
            }
        }

        .its-custom-chart--text {
            overflow: auto;
            word-wrap: break-word;
        }
    }

    .its-custom-chart--content.no-title {
        margin-top: 20px;

        .its-custom-chart--loading-state {
            margin-top: 0;
            height: 100%;
            align-items: center;

            iti-chart-loading {
                margin-top: 0;

                .its-chart--loader {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }

        .its-custom-chart--empty-state {
            margin-top: 0;
            height: 100%;
            align-items: center;

            iti-alert-message {
                margin-top: 0;
            }
        }

        .its-custom-chart--content--image {
            img {
                display: block;
                max-width: 100%;
                max-height: 410px;
                width: auto;
                height: auto;
            }

            margin-top: -20px;
            height: 410px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        .its-custom-chart--content--data-table--container {
            max-height: 100%;
        }
    }

    .its-custom-chart--content-highchart {
        @extend .its-custom-chart--content;
    }
}

//temp datatable override
.its-custom-chart {
    .its-datatable {
        border: 1px solid $BorderColour;
        box-shadow: none;
    }
}
