.its-comment-panel-container {
    max-width: 100%;
    height: calc(100% - 59px);
    border-radius: 4px;
    transform-origin: right top;
    -webkit-transition: opacity 150ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 150ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
    opacity: 0.51;

    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);

    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, tranform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    @include media-breakpoint-up('sm') {
        max-width: 33%;
    }

    &.its-panel {
        min-height: 100%;
        overflow-y: auto !important;
        min-width: 400px;
        max-width: 400px;
        width: 400px;

        &._md-panel-fullscreen {
            max-width: 100% !important;
            width: 100%;
        }
    }

    .its-panel {
        min-height: 100%;
        overflow-y: auto !important;
    }

    .its-comment-panel--content {
        opacity: 0.001;
        transition: opacity 50ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
    }

    &.its-panel--open {
        transform-origin: right top;
        transform: scale3d(1, 1, 1);
        opacity: 1;

        .its-comment-panel--content {
            opacity: 1;
        }
    }

    .md-list-item-text {
        p {
            font-weight: 400 !important;
        }
    }
}

.its-comment-panel {
    min-height: 100%;
    min-width: 100%;

    .comment-container {
        .btn-close-panel {
            margin-left: -10px;
            margin-bottom: 10px;
        }

        .comment-container--header {
            margin: 0;

            .comment-container-comments--sort {
                md-icon {
                    font-size: 20px;
                    height: 20px;
                    width: 20px;
                    min-height: 20px;
                    min-width: 20px;
                    line-height: 20px;
                    margin-right: 6px;
                    color: rgba(0, 0, 0, 0.87);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .comment-uploaded-image-preview {
            width: 112px;
            height: 72px;
            border: 1px solid rgba(0, 0, 0, 0.08);
            margin-bottom: 12px;
            text-align: center;
            vertical-align: middle;
            position: relative;

            &.is-file {
                width: unset;
                height: unset;
                display: inline-block;
                padding: 3px 5px;
            }

            img {
                max-height: 100%;
                max-width: 100%;
            }

            md-icon {
                @include md-icon-size(30px);
            }

            .comment-uploaded-image-preview--remove {
                display: none;
                background: white;
                border-radius: 100%;
                border: 1px solid rgba(0, 0, 0, 0.08);
                padding: 2px;
                position: absolute;
                top: 2px;
                right: 2px;

                md-icon {
                    font-size: 20px;
                    height: 20px;
                    width: 20px;
                    min-height: 20px;
                    min-width: 20px;
                    line-height: 20px;
                }

                &:hover {
                    background: rgb(244, 244, 244);
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
                    cursor: pointer;
                }
            }

            &:hover {
                .comment-uploaded-image-preview--remove {
                    display: inline-block;
                }
            }
        }

        .comment-actions {
            border: 1px solid $BorderColour;
            border-top: none;

            .its-button {
                min-width: auto;
                padding: 0 4px;

                &.md-primary {
                    box-shadow: none;
                    padding: 0 12px;
                }
            }
        }
    }

    .comment-container-comments {
        .comment-container-comments--comment {
            .comment-container-comments--comment-header {
                margin-bottom: 2px;

                span {
                    margin-left: 8px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }

            .comment-container-comments--comment-body {
                color: rgba(0, 0, 0, 0.54);
                font-weight: 400;

                .its-mention {
                    font-weight: 600;
                }
            }

            .md-list-item-text {
                margin: 16px 0;
            }

            .comment-container-comments--comment-media {
                display: inline-block;
                position: relative;

                background: rgba(0, 0, 0, 0.04);
                border: 1px solid rgba(0, 0, 0, 0.08);
                padding: 4px;
                box-sizing: border-box;
                margin-top: 12px;
                margin-bottom: 8px;

                p {
                    line-height: 1.2em;
                }

                .comment-container-comments--md-icon {
                    background-color: #ffffff;
                    text-align: center;
                    min-width: 100px;
                    max-width: 150px;
                    padding: 3px 5px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                md-icon.md-large {
                    @include md-icon-size(42px);
                }

                its-dynamic-image {
                    height: 112px;
                    width: 120px;
                    justify-content: center;
                    background: white;
                    display: flex;
                    margin: 0 auto;
                    cursor: pointer;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        align-self: center;
                    }
                }

                .comment-container-comments--comment-media-file {
                    vertical-align: text-bottom;
                }

                .comment-container-comments--image-download {
                    display: inline-block;
                    background: white;
                    border-radius: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.08);
                    padding: 2px;
                    margin-top: 4px;

                    md-icon {
                        font-size: 22px;
                        height: 22px;
                        width: 22px;
                        min-height: 22px;
                        min-width: 22px;
                        line-height: 22px;
                        color: $basePrimaryColour;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .comment-container-comments--comment--md-icon-top-0 {
                position: absolute;
                top: 0;
            }
        }
    }
}
