.its-product-filter--capture-padding {
    padding-left: 30px;
}

.its-product-filter--product-padding {
}

.its-product-filter--menu-container.md-select-menu-container {
    > md-select-menu {
        max-height: initial;

        > md-content {
            max-height: initial;
        }
    }
}
