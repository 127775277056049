.its-toolbar--responsive {
    position: fixed;
    width: calc(100% - 64px);
}

@media (max-width: 600px) {
    .its-toolbar--responsive {
        .md-toolbar-tools {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (max-width: 960px) {
    .its-toolbar--responsive {
        width: 100%;
    }
}

.its-iq-faux-sub-tabs-container {
    margin: 12px 12px 0;
}

.its-iq-faux-tabs-container {
    padding-left: 10px;
    background: white;
    z-index: 1;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    min-height: 50px;
    box-shadow: none;
}

.its-iq-faux-tabs {
    .its-iq-faux-tab {
        text-transform: uppercase;
        margin-right: 20px;
        font-weight: 500;
        cursor: pointer;
        padding: 10px;
        color: rgba(0, 0, 0, 0.4);
        border-bottom: 2px white solid;
        height: 100%;

        &.md-button:hover {
            background: #f7f7f7;
        }

        &.disabled {
            cursor: default;
            color: #aaa;
        }

        &.active {
            color: rgb(63, 158, 204);
            font-weight: 500;
            border-bottom: 2px #3f9ecc solid;
        }
    }
}

.its-traditional-tabs {
    &.its-traditional-tabs--device-report {
        margin-left: 10px;
        margin-right: 10px;
    }

    .its-traditional-tab {
        background: #fff;
        cursor: pointer;
        padding: 15px;
        box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14), 1px 0px 0px 0px rgba(0, 0, 0, 0.12),
            -1px -0.5px 0px 0.5px rgba(0, 0, 0, 0.12);

        &.its-traditional-tab--inactive-outline {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        &.md-button:hover {
            background: #f7f7f7;
        }

        &.disabled {
            cursor: default;
            color: #aaa;
        }

        &.active {
            color: rgb(63, 158, 204);
            font-weight: 500;
            border-bottom: none;
        }
    }
}
