.pdf {
    background-color: #fff;
    min-height: inherit;
    padding: 20px;

    #its-content {
        background-color: #fff;
        box-shadow: none !important;
    }

    .its-document--content--organization-logo img {
        height: 100px;
    }

    .its-iq-content-container-pdf--condensed {
        padding: 0;

        .its-document--header,
        md-card-title {
            padding: 0 !important;
        }

        .its-document--section--question {
            padding-left: 50px;
        }

        md-card-content {
            padding-bottom: 0;
            padding-top: 10px;
        }
    }

    #its-sidebar,
    #its-topbar,
    .its-sub-menu {
        display: none;
    }
    .its-iq-content-with-subnav {
        margin: 0;
    }
    #its-content {
        padding: 0;
    }
    .its-sidebar--padding {
        margin: 0;
    }

    .its-iq-content-with-subnav {
        margin: 0;
        padding: 0;
    }

    .its-filters-select {
        margin: 0;
        display: none;
    }

    .its-document--section md-list {
        padding: 0 !important;
        margin: 0;
    }

    .its-document .its-document--header {
        padding: 20px;

        .its-document--header-container {
            display: table;
            width: 100%;
        }

        .its-document--title-section {
            display: table-cell;
        }

        .its-document--title--score-section {
            display: table-cell;
            text-align: right;
        }

        .its-document--title--score,
        .its-document--title--score-centered {
            display: block;
            .its-document--title--score-centered {
                display: block;
            }
        }
    }

    .its-table md-list-item {
        min-height: inherit;
    }

    .its-table-data {
        padding: 0;
        margin: 0;

        .its-records-question-container .its-table span {
            padding: 0;
            word-wrap: break-word;
            white-space: normal;
        }

        md-list-item.its-table--row {
            font-weight: 200;
            break-inside: avoid;
            height: auto;

            &::before {
                min-height: 30px;
            }
        }
    }

    .its-document {
        height: inherit;

        .its-document--content {
            padding: 0;
            margin: 0;
            box-shadow: none !important;
        }

        .its-document--header {
            background: #fff;
            padding: 0;
        }

        .its-document--section .its-document--section--question {
            margin: 0;
            display: table;
            padding-left: 50px;
        }

        .its-document--section .its-document--section--score {
            line-height: 1.5;
            -webkit-margin-before: 1em;
            -webkit-margin-after: 1em;
            display: table-cell;
            text-align: right;
        }

        .its-document--section .its-document--section--title {
            display: table-cell;
        }

        .its-document--section .its-document--section-heading {
            display: table;
            width: 100%;
        }

        .its-document--section .its-document--section--question .its-document--section--question-info {
            display: table-cell;
        }

        .its-document--section .its-document--section--question .its-document--section--question--responses {
            padding-bottom: 5px;
        }

        .its-document--section .its-document--section--question .its-document--section--question--label {
            font-weight: 400;
            color: #4a4a4a;
        }

        .its-document--section .its-document--section--question .its-document--section--question--avg-score {
            display: inline-block;
        }

        .its-document--section .its-document--section--question .its-document--section--question--data {
            display: table-cell;
            text-align: right;
        }

        .its-document--section--question--comment,
        .its-document--section--question--attachments {
            margin-bottom: 10px;
            border-left: 1px solid #eee;
            margin-left: 25px;
            background-color: #fff;
        }
    }
}
