.its-iq-modal {
    md-dialog-title {
        background-color: $iqProductColor;
        padding: 10px 20px 10px 20px;

        h2 {
            color: #fff;
        }
    }

    .its-iq-modal--section {
        padding: 20px;
    }
}

.its-iq-modal__large md-dialog-content {
    min-height: 750px;
}
