.its-attention-table {
    margin: 10px 0 0 0;
}

.its-pdf-record-summary-label {
    margin-top: 8px;
}

.its-pdf-record-summary {
    .its-summary-container {
        width: 1000px;
        margin: auto;
    }

    .its-document--content--organization-logo {
        margin: 1in 0 0 0;
        max-width: 150px;
    }
    .its-program-header {
        display: flex;
        justify-content: center;
        padding: 50px 0 10px 0;
    }

    .its-location-header {
        display: flex;
        justify-content: center;
        padding: 10px 0 50px 0;
        h2 {
            font-size: 12pt;
            font-weight: bold;
        }
    }

    .its-cover-page {
        display: flex;
        align-items: center;
        justify-content: center;
        .its-cover-page-meta {
            font-size: 12pt;
            width: 550px;
            display: flex;
            justify-content: center;
        }
        h3 {
            font-weight: bold;
            font-size: 150%;
        }
    }

    .its-cover-page.its-percentage {
        margin-left: 1em;
    }

    .its-cover-page-tally {
        margin: 0 0 0 0;
    }

    .its-top-level-group-heading {
        font-weight: bold;
        display: flex;
        justify-content: center;
    }

    .its-top-level-group-subheader {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: center;
    }

    .its-pdf-summary-section-grouping {
        margin-bottom: 50px;
    }

    .its-items-requiring-attention {
    }

    .its-box {
        padding: 20px;
        border: 1px solid #000000;
        border-top: 0;
        ul {
            margin-left: 0;
            padding-left: 0;
        }
        li {
            list-style-type: none;
        }
    }

    .its-box.its-question {
        border-right: 1px solid #000000;
        border-top: 1px solid black;
    }

    .its-box.its-tag {
        border-right: 0;
        border-top: 1px solid black;
    }

    .its-box.its-tag-score {
        border-left: 1px solid #000000;
        font-weight: bold;
        border-top: 1px solid black;
    }

    .its-text-question {
        border-right: 1px solid black;
        border-top: 1px solid black;
    }
    .its-box.its-answer {
        border-left: 0;
        font-weight: bold;
        border-top: 1px solid black;
    }

    .its-question-tag {
        font-size: 15px;
        min-height: 15px;
        width: 15px;
        min-width: 15px;
        height: 15px;
    }

    .its-comment {
        white-space: pre-line;
    }

    .its-section-box {
        padding: 20px;
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
    }

    .its-heading {
        border-left: 1px solid #000000;
    }

    .its-score {
        border-right: 1px solid #000000;
    }

    .its-box-ok {
        background-color: #69b647;
    }

    .its-box-not-ok {
        background-color: #ff5555;
    }

    .its-qa-group {
        margin: auto;
        margin-top: 1em;
    }

    .its-all-responses {
    }

    .its-tag-summary {
        margin-top: 50px;
    }

    .its-all-tags-header {
        margin: auto;
        margin-top: 1em;
    }

    .its-tag-heading {
        border-left: 1px solid #000000;
        border-right: 1px solid #000000;
    }

    .its-tag-score {
        border-right: 1px solid #000000;
    }

    .its-all-responses-header {
        margin: auto;
        .its-box {
            font-weight: bold;
        }
        .its-section-box {
            font-style: italic;
        }
    }

    .its-columns {
        display: flex;
        break-inside: avoid;
    }
    .its-box {
        break-inside: avoid;
    }

    .its-column {
        flex: 1;
    }

    .its-record-summary-attachment-thumbnail {
        max-width: 100px;
    }

    .its-image-section {
        page-break-before: always;
    }

    .its-image-gallery {
        //display: flex;
        flex-wrap: wrap;
    }

    .its-record-summary-attachment-fullsize {
        img {
            max-width: 400px;
            max-height: 400px;
            margin: 0.5em;
        }
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: 400px;
        min-width: 400px;
        float: left;
    }

    .its-break-me {
        break-after: always;
        break-inside: avoid;
    }

    .its-record-summary-attachment-fullsize.its-img-label {
        color: #312e2e;
    }

    .its-attachment-list-item {
        list-style-type: none;
        display: inline;
    }
    .its-attachment-list-item:not(:last-child) {
        padding-right: 10px;
    }
    .its-attachment-comments {
        font-size: 9pt;
    }
}
