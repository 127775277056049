.its-action-campaigns {
    .its-action-campaigns--warning-text {
        font-size: 0.9em;
        opacity: 0.7;
        margin-top: 10px;
        margin-left: 20px;
    }

    .its-action-campaigns--chart-title {
        @extend .its-custom-chart--title;

        position: relative;

        .its-custom-chart--chart-type {
            position: absolute;
            right: 0;
            top: 0;
            background-color: white;
            padding-left: 20px;
            padding-right: 20px;

            md-select {
                margin: 15px 0 0 0;

                md-select-value {
                    border: none;
                    font-weight: 400;
                }
            }
        }
    }

    .its-action-campaigns--negative-delta-icon {
        color: darkred;
    }

    .its-action-campaigns--positive-delta-icon {
        color: green;
    }

    md-content {
        background-color: transparent;
    }

    .its-action-campaigns--list {
    }

    .its-action-campaigns--dashboard {
        .its-action-campaigns--dashboard-header {
            background-color: #fff;
            margin: 20px 20px 0 20px;
            padding: 20px;
            font-size: 1.5em;
            opacity: 0.8;
        }

        .its-action-campaigns--dashboard-content {
            padding: 0 10px 10px;
        }
    }
}

.its-action-campaigns--overview {
    @extend .its-custom-chart;

    min-height: 168px;
    max-height: 168px;
    height: initial;

    .its-action-campaigns--overview--elapsed {
        width: 100%;
    }

    .its-action-campaigns--overview--elapsed-bar {
        height: 12px;
        margin-right: 20px;
        margin-left: 20px;
        background-color: #ddd;

        .its-action-campaigns--overview--elapsed-bar--fill {
            background-color: $basePrimaryColour;
            height: 12px;
            max-width: 100%;
        }
    }

    .its-action-campaigns--overview--loading-state {
        @extend .its-custom-chart--loading-state;

        .its-chart--loader {
            margin-top: 50px;
        }
    }
}

.its-action-campaigns--description {
    min-height: 168px;
    max-height: 168px;
    padding: 20px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.its-action-campaigns--editor {
    .its-action-campaigns--editor--location--selector {
        md-chips-wrap {
            padding-top: 25px;
        }
    }

    .its-action-campaigns--editor--checklists {
        background-color: #eee;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 3px;

        .its-action-campaigns--editor--checklists--checklist-headers {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .its-action-campaigns--editor--checklists--checklist {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .its-action-campaigns--editor--checklists--button {
            margin: 10px;
        }

        .its-action-campaigns--editor--checklists-cell {
            margin: 10px;
            font-size: 12px;
            word-wrap: normal;
        }

        .its-action-campaigns--editor--checklists-cell-header {
            opacity: 0.7;
            line-height: normal;
            letter-spacing: normal;
            margin: 10px;
            font-size: 12px;
        }
    }

    .its-action-campaigns--editor--action-button-row {
        padding-right: 10px;
    }

    .ite-selectable-search-container {
    }
}

.its-action-campaigns--description-header {
    color: rgba(0, 0, 0, 0.38);
    margin-left: 5px;
}

.its-action-campaigns--editor--no-checklist-message {
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 5px;
    opacity: 0.8;
}

.its-add-kpi-modal {
    its-empty-state {
        .its-empty-state {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    its-error-state {
        .its-error-state {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}

.its-action-campaigns-actions-completion-column {
    max-width: 100px;
}

.its-action-campaign-actions-completion-rate-column {
    max-width: 150px;
}

.its-action-campaigns-actions-frequency-column {
    max-width: 500px;
}

.its-action-campaigns-actions-name-column {
    max-width: 500px;
}

.its-action-campaigns-actions-groups-column {
}
