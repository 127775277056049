.its-list-sidenav {
    margin-right: 0;
    border-right: 1px solid #ccc;
    .its-list-sidenav--section.custom {
        .its-list-sidenav--items-list {
        }
    }

    .its-list-sidenav--footer {
        z-index: 1;
        background: white;
    }
}

.its-dashboard {
    .its-dashboard--name-print {
        display: none;
        font-size: 16px;
        font-weight: 500;
        @media print {
            display: flex;
        }
    }

    .its-empty-state {
        margin-top: 0;
    }

    md-content {
        background-color: transparent;
    }

    .its-dashboard--content {
        padding: 0 5px 10px 5px;

        @include media-breakpoint-down(sm) {
            padding: 8px;
        }
    }

    .its-dashboard--content-view {
    }

    &.its-dashboard-view {
        margin-top: 65px;
    }

    .its-dashboard--chart-max-message {
        margin-bottom: 23px;
        font-weight: bolder;
    }

    .its-dashboard--toolbar-container {
        z-index: 1;

        .its-dashboard--toolbar {
            //position: fixed;
            //top: 120px;
            // z-index: 2;
            // left: 0;
            //width: 100%;
            background-color: white;
            //padding-top: 0;
            //padding-bottom: 0;
            //margin-bottom: 0;
            border-bottom: 1px solid #ddd;

            @include media-breakpoint-down(sm) {
                padding-top: 0;
                top: 110px;
            }

            iti-custom-chart-types-toolbar {
                padding-bottom: 0;
                margin-left: 5px;

                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }
            }

            .its-dashboard--toolbar--icons-list {
                padding-bottom: 0;

                md-list {
                    padding: 0;
                }

                .its-dashboard--toolbar--icon {
                    .its-dashboard--toolbar--disabled-icon {
                        opacity: 0.4;
                        &.its-selected {
                            opacity: 1;
                        }
                        .md-button:hover {
                            background: none;
                        }
                    }

                    md-list-item {
                        height: 100%;
                        padding: 0;
                    }

                    .its-selected {
                        .md-button {
                            opacity: 1;
                            border-bottom: 1px solid rgb(63, 158, 204);
                        }
                    }

                    cursor: pointer;
                    padding: 0;

                    .md-button {
                        min-width: 0;
                        opacity: 0.7;
                        padding: 6px 16px 6px 16px;
                    }

                    img {
                        height: auto;
                        width: auto;
                    }
                }
            }

            .its-dashboard--toolbar--menu {
                padding-bottom: 12px;
            }
        }
    }

    .its-dashboard--header {
        // margin-top: 10px;
        min-height: 50px;
        max-height: 50px;
        @media print {
            display: none;
        }
    }

    .its-dashboard--preview {
        height: 100%;

        .dndDragover {
            border: 4px dashed rgb(63, 158, 204);
            list-style: none;
        }

        .its-dashboard--preview--empty-state {
            padding-bottom: 20px;
        }

        .its-dashboard--preview--charts {
            .dndDragover {
                border: none;
                border-left: 4px dashed rgb(63, 158, 204);
                list-style: none;
            }

            .its-dashboard--preview--charts--chart {
                @media print {
                    .its-custom-chart--content {
                        overflow: hidden !important;
                        line-height: initial;
                    }

                    .its-custom-chart--text {
                        div {
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }

    .its-dashboard--chart-editor {
        padding: 20px;
        background-color: #ffffff;
    }
}

.its-list-sidenav--section--search {
    background-color: $backgroundLightGrey;
}

.its-list-sidenav--section--header {
    transition: all ease 150ms;
    &:hover {
        background: #eaeaea;
    }
    h3 {
        font-size: 16px;
        font-weight: 500;
        color: #444;
    }

    .its-list-sidenav--section--header--icon {
        md-icon {
            color: #444;
        }
    }
}

.its-datalist--icon-margin {
    margin: 12px 18px;
}

.its-list-sidenav--section--header-active {
    h3 {
        color: $primaryIntelligenceColour;
    }
    .its-list-sidenav--section--header--icon {
        md-icon {
            color: $primaryIntelligenceColour;
        }
    }
}

.its-list-sidenav--empty-state {
    .its-empty-state--text {
        text-align: center;
        margin-right: 30px;
        margin-left: 30px;
        max-width: 240px;
    }
}

.its-list-sidenav--footer-collapsed {
    margin-top: auto;
}

.its-list-sidenav--footer-collapsed--expand-container {
    height: 70px;
    border-top: 1px solid #ddd;
}

.its-list-sidenav--footer-collapsed-expand-icon-transform {
    transform: translateX(10px);
}

.its-list-sidenav--solo-tab-override {
    h3 {
        color: black;
    }
    .its-list-sidenav--section--header--icon {
        md-icon {
            color: black;
        }
    }
    &:hover {
        background: white;
        cursor: default;
    }
}

.its-dashboard--load-more {
    margin: 0;
    padding: 12px 0 12px 0;
    color: rgba(0, 0, 0, 0.42);
    cursor: pointer;
}

//PRINT STYLES
@media print {
    html,
    body {
        background-color: white;
    }

    .its-dashboard {
        background-color: white;
    }

    .its-dashboard,
    .its-dashboard--preview {
        height: unset !important;
    }
    .its-custom-chart--content-highchart {
        height: 100%;
    }
    .its-custom-chart--empty-state {
        height: 80% !important;
    }
}

.its-dashboard--chip {
    .md-errors-spacer {
        display: none;
    }
}

.its-dashboard--sharing-settings-spacer {
    height: 200px;
}

.its-dashboard--chip-validation-message {
    color: rgb(221, 44, 0);
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    padding-top: 5px;
}

.its-dashboard--selector-container {
    margin-top: -15px;
}

// Create Dashboard Modal Styles

.its-modal md-dialog-content {
    .its-dashboard--create-modal-label {
        color: #868686;
        margin-bottom: 0;
        font-size: 14px;
    }
}

.its-dashboard-record-summary {
    .its-dashboard-record-summary--question-header-row {
        margin-bottom: 10px;
        span {
            opacity: 0.7;
            font-weight: 500;
        }
    }

    .its-dashboard-record-summary--question-response-row {
        margin-top: 8px;
        padding-bottom: 8px;
        span {
            font-weight: 500;
        }

        .its-table-data--percentage-bar-container {
            margin-top: 0;
            background-color: #ddd;
        }
    }

    .its-content-tile {
        padding: 25px;
    }
}
