.its-alert-message {
    text-align: center;
    font-weight: 400;
    opacity: 0.4;
    font-size: 14px;

    .its-alert-message--icon {
        font-size: 3.5em;
        width: 100%;
    }
    .its-alert-message--text {
        margin-top: 40px;
    }

    .its-alert-message--icon-url {
        height: 35px;
        width: auto;
    }
}
