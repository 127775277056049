.its-table--row__regular {
    font-weight: $fontWeightNormal;
}

.its-table--row {
    span.flex {
        width: 0;
    }
}

.its-positive-light {
    background: rgba(180, 253, 200, 0.3);
}

.its-warning-light {
    background: rgba(253, 232, 180, 0.3);
}

.its-danger-light {
    background: rgba(255, 73, 73, 0.05);
}

.its-table-header {
    @extend .its-text--caption;
    @extend .its-text__strong;
    @extend .its-text__uppercase;
    color: $FontColour_Light;
}
.its-table-cell {
    @extend .its-text__strong;
    color: $FontColour_Muted;
}

.its-with-create {
    margin-bottom: 100px;
}

.its-with-search {
    margin-top: 80px;
}

.its-sortable-header {
    cursor: pointer;
}

.its-not-sortable-header {
    cursor: default;
    margin-top: 5px;
}

.its-table--date {
    min-width: 165px;
    max-width: 200px;
}

.its-table--more-menu {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
}

.its-table--min-col-width {
    min-width: 140px;
}

.its-table--min-col-width-medium {
    min-width: 200px;
}

.its-table--sm-col-width {
    min-width: 130px;
    max-width: 200px;
}

.its-table--xs-col-width {
    min-width: 80px;
    max-width: 200px;
}
