iti-filter-data-group-set {
    .its-dnd-list {
        min-height: 40px;
        margin: 0;

        .dndPlaceholder {
            list-style-type: none !important;
            width: inherit;
            height: 40px;
            background: #eee;
            margin: 10px;
            border-radius: 4px;
            border: 1px dashed #ddd;
        }
    }
}
