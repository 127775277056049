.iti-loading-login {
    text-align: center;
    padding: 15px;
    margin-top: 100px;

    .iti-loading-login--logo {
        width: 50px;
        height: 50px;
        animation: rotate 1.3s ease-out infinite;
    }

    .no-animation {
        animation: none;
    }

    @keyframes rotate {
        40% {
            transform: rotateY(180deg);
        }
        55% {
            transform: rotateY(180deg);
        }
        90% {
            transform: rotateZ(-180deg);
        }
        100% {
            transform: rotateZ(-180deg);
        }
    }
}

.iti-loading {
    margin-top: 140px;
}
