#its-sidebar {
    &::-webkit-scrollbar-thumb {
        background: $basePrimaryColour85;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $basePrimaryColour;
        }
    }
}

.min-full-width {
    min-width: 100%;
}

.hidden-input {
    visibility: hidden !important;
    height: 0 !important;
    max-height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
}

// add this class to md-input-container holding an md-datepicker to create full-length
.date-picker {
    &._md-datepicker-floating-label {
        &._md-datepicker-has-calendar-icon {
            > label {
                &:not(.md-no-float) {
                    &:not(.md-container-ignore) {
                        left: 34px;
                        right: 0;
                    }
                }
            }
        }
    }

    md-datepicker {
        display: inline-block;
        padding: 0;
        width: 100%;

        .md-datepicker-button {
            margin-left: -12px;
        }

        .md-datepicker-input-container {
            display: flex;

            .md-datepicker-input {
                max-width: 100%;
                min-width: 100%;
            }

            .md-datepicker-triangle-button {
                right: 8px;
            }
        }
    }

    .md-input-messages-animation {
        .md-input-message-animation {
            margin-left: 34px;
        }
    }
}

.datepicker-arrow-bottom-0 {
    md-datepicker .md-button.md-icon-button.md-datepicker-triangle-button {
        bottom: 0px !important;
    }
}

.its-responsive-text {
    font-size: 72px;
    font-size: 10vh;
}

#its-topbar {
    position: relative;
}

#its-content {
    padding-top: 0;
}

.its-toolbar {
    width: 100%;
}

.its-tool-tip {
    display: flex;
}

md-card {
    border-radius: 3px;
}

.transparent-background {
    background: transparent;
}

.horizontal-scroll {
    overflow-x: scroll;
}

//Print override
@media print {
    body {
        height: unset;
    }

    .its-mobile-toggle {
        display: none;
    }

    .its-chart {
        overflow: hidden !important;
    }

    .its-chart-container:nth-of-type(even) {
        page-break-after: always;
        margin-bottom: 75px;
    }

    .print-show {
        display: flex !important;
    }
}

.ite-content-filter {
    padding-left: 10px;
    padding-right: 10px;
    .ite-content-filter--selection--icon,
    .ite-content-filter--selection--arrow {
        color: $White;
    }
}

//overrides for dashboard printing the submenu filter chips
.its-sub-menu--dashboard-print {
    @media print {
        border-bottom: none !important;
        display: flex;
        box-shadow: none;
        background-color: white !important;

        .md-icon-button {
            display: none;
        }

        md-icon {
            display: none;
        }

        .its-iql-global-filters--chip {
            -webkit-print-color-adjust: exact !important;
        }

        .its-iql-global-filters--add-filter {
            display: none;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .its-ie10plus--disable-overflow {
        overflow: visible !important;
    }
}

/** Froala override to prevent placeholders skipping. **/
.fr-element {
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    menu,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
}

.its-froala .fr-toolbar.fr-top,
.its-froala .fr-toolbar,
.its-froala .fr-second-toolbar {
    border-radius: 0;
}

.fr-toolbar.fr-top {
    border-radius: none;
    border: 1px solid #eee;
}

.fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
    border: 1px solid #eee;
    border-top: 0;
}

.fr-toolbar .fr-command.fr-btn svg.fr-svg {
    height: 20px;
}

.fr-toolbar .fr-command.fr-btn svg {
    width: 20px;
}

.md-color-picker-preview {
    border: none !important; // fixes issue with color picker when browser zoom is not 100%. Also looks cleaner.
}

md-input-container {
    &.hide-error-spacer {
        .md-errors-spacer {
            display: none;
        }
    }
}

.its-hidden-md-select-container {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
}

.its-datalist--cell--with-threshold-color {
    div {
        span {
            line-height: 40px;
        }
    }
}

.fr-popup {
    .fr-input-line {
        input + label {
            top: -3px !important;
        }
    }
}

.its-dnd-list {
    &:not(.its-prevent-override) {
        list-style-type: none !important;
        margin: 0 !important;

        .dndDraggingSource {
            display: none;
        }

        .dndPlaceholder {
            height: 40px;
            background: $LightGrey;
            border-radius: $BorderRadius;
            border: $Border_dashed;
        }

        .its-dnd-item {
            padding: 0 !important;
            margin: 3px 0 !important;
        }
    }

    &.its-dnd-metrics {
        min-height: 40px;
        list-style-type: none !important;

        .dndDraggingSource {
            display: none;
        }

        .dndPlaceholder {
            height: 40px;
            background: $LightGrey;
            border-radius: $BorderRadius;
            border: $Border_dashed;
        }
    }
}

.its-dnd-drag-source {
    .dndDraggingSource {
        display: flex !important;
        background: $LightGrey;
        opacity: 0.5;
    }
}

/**
 * Need this for some dynamic dashboard pdfs
 * Removing `overflow: visible !important;` from _its.print.scss
 * fixes it, but removing that made pdfs slow af.
 * So here we are
 **/
.print-overflow-hidden {
    @media print {
        overflow: hidden !important;
    }
}

.its-select-element.custom-padding {
    padding-top: 5px;
    padding-bottom: 5px;
}
