.its-journey-maps--title {
    margin-bottom: 40px;
}

.its-journey-maps--lanes-card {
    margin-left: 50px;
}

.its-journey-maps--stage {
    margin-bottom: 60px;

    &__last {
        margin-bottom: 0 !important;
    }
}

.its-journey-maps--lane {
    margin-bottom: 40px;

    &__last {
        margin-bottom: 0 !important;
    }
}

.its-journey-maps--programs {
    padding: 20px 30px;
    background: #f8f8f8;
    border-bottom: 1px solid #eee;
    margin-top: 40px;

    hr {
        height: 1px;
        background: #d2d2d2;
        border: 0;
    }
}

.its-journey-maps--header {
    display: block;
    margin-left: auto;
    height: 50px;
}

.its-journey-maps--lane-image-upload-container {
    margin-bottom: 20px;
}

.its-journey-maps--lane-image-upload {
    display: inline-block;
    position: relative;

    .its-journey-maps--lane-image-upload-remove {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.54);
        border-radius: 10%;
        margin: 6px;
        &.md-button:hover {
            background-color: rgba(255, 255, 255, 0.74);
        }
    }
}

.its-journey-maps--dashboard-card {
    overflow-x: scroll;
}

.its-journey-maps--stage-ribbon {
    line-height: 40px;
    text-align: center;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;

    .its-journey-maps--stage-ribbon-arrow {
        content: ' ';
        position: absolute;
        top: 0;
        right: -20px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
        z-index: 2;
        transition: border-color 0.2s ease;
    }

    &:last-child .its-journey-maps--stage-ribbon-arrow {
        border: 0;
    }
}

.its-journey-maps--lane-container {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 8px;

    &:nth-child(odd) {
        background: #fafafa;
    }

    &:nth-child(even) {
        background: #f5f5f5;
    }

    &:last-child {
        border-right: 0;
    }

    @include media-breakpoint-up(md) {
        width: 50%;
    }

    @include media-breakpoint-up(lg) {
        width: 33.33333333333%;
    }

    @include media-breakpoint-up(xl) {
        width: 100%;
        flex-grow: initial;
        flex-shrink: initial;
        min-width: 400px;
    }
}

.its-journey-maps--lane-container__clickable {
    cursor: pointer;
}

.its-journey-maps--lane-title {
    margin: 25px 0;
}

.its-journey-maps--lane-image {
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(xl) {
        height: 360px;
    }
}

.its-journey-maps--lane-image-missing {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    md-icon {
        font-size: 5em;
        margin-bottom: 60px;
    }
}

.its-journey-maps--lane-chart {
    height: 210px;
    margin-bottom: 15px;
    margin-left: -10px;
    margin-right: -10px;
}

.its-journey-maps--lane-chart-missing {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    md-icon {
        font-size: 5em;
        margin-bottom: 60px;
    }
}

.its-journey-maps--empty-journey-map {
    text-align: center;

    md-icon {
        font-size: 5em;
        margin-bottom: 40px;
    }
}

.its-journey-maps--programs-list {
    background: #f8f8f8;
    margin-top: 40px;

    .its-journey-maps--programs-list-program {
        border-bottom: 1px solid #eee;
        min-height: 64px;

        &:last-child {
            border-bottom: 0;
        }
    }
}

.its-journey-maps--lane-program {
    margin: 15px 0;
    text-align: left;
}

.its-journey-maps--lane-program__greyed-out {
    opacity: 0.5;
}

.its-journey-maps--jm-wrapper {
    padding: 0;
}

md-input-container .md-input-message-animation:not(.ng-animate).its-journey-maps--lane-validation-msg {
    opacity: 1;
    margin-top: 0;
}
