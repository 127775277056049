$basePrimaryColour: #00a3e0;
$basePrimaryColour85: rgba(0, 163, 224, 0.85);
$backgroundLightGrey: #f6f6f6;
$backgroundDarkGrey: darken($backgroundLightGrey, 20%);
$brandLightGray: #58595b;
$iqProductColor: rgb(63, 158, 204);
$textOverProductColor: rgba(255, 255, 255, 0.87);
$PrimaryColour: $IntelligenceColour_Primary;
/*
 * Component color palette
 */
$textRed: #a94442;
$textYellow: #8a6d3b;
$textBlue: #31708f;
$textGreen: #3c763d;
$textError: #f44336;
$textWarning: #ffd700;
