.its-card-header {
    min-height: 105px;
    padding: 20px;
}

.its-survey-card {
    font-size: 3em;

    @media (max-width: 600px) {
        font-size: 2em;
    }
}
