.its-icon-picker-input {
    height: 36px;
    max-height: 36px;
    width: 36px;
    max-width: 36px;
    margin-bottom: 20px;
}

.its-icon-picker-panel {
    .its-selected {
        background-color: $color-primary-50;
        color: $PrimaryColour;

        &:hover {
            background-color: $color-primary-100;
        }
    }
}
