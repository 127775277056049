.its-hierarchy-node-tree-item--item {
    min-height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid $BorderColour;

    its-datalist-header {
        .its-datalist--header {
            height: 48px;
        }
    }

    .its-hierarchy-node-tree-item--item--icon {
        margin-right: 5px;
        width: 24px;
    }
}
