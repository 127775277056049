iti-back-button {
    @media print {
        display: none;
    }
}

iti-reload-button {
    @media print {
        display: none;
    }
}

ite-filter-panel-access-button {
    @media print {
        display: none;
    }
}
