.its-main--datalist {
    margin: 6px 6px;
    padding-top: 20px;
    background-color: white;

    .its-main--datalist--title {
        @extend .its-text--title;
    }

    .its-main--datalist--subhead {
        @extend .its-text__muted;
    }

    .its-main--product-summary--card-content {
        margin-bottom: 20px;
        margin-top: 20px;

        .its-main--product-summary--card-content--row {
            margin: 15px;
        }

        .its-main--product-summary--card-content--count {
            text-align: right;
            margin-right: 15px;
        }

        .its-main--product-summary--card-content--name {
            @extend .its-text__muted;
        }
    }
}

.its-main-card {
    min-height: 300px;

    .its-main-card--full-width {
        // IE 11 fix for scrunched stuff
        width: 100%;
    }
}
