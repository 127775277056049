.md-panel {
    overflow-y: auto;
    overflow-x: hidden;
    @extend .layout-column;

    &.its-filter-panel-container {
        max-width: 300px;
        border-radius: 4px;
        transform-origin: right top;
        -webkit-transition: opacity 150ms cubic-bezier(0.8, 0, 0.6, 1) 0ms,
            transform 150ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
        opacity: 0.51;

        box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);

        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, tranform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        .its-filter-panel--content {
            opacity: 0.001;
            transition: opacity 50ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
        }

        &.its-panel--open {
            transform-origin: right top;
            transform: scale3d(1, 1, 1);
            opacity: 1;

            .its-filter-panel--content {
                opacity: 1;
            }
        }
    }

    &._md-panel-fullscreen {
        height: 100%;

        .its-panel {
            max-height: 100%;
            max-width: 100%;
            height: 100%;
            width: 100%;
        }

        .its-paged-multi-select {
            height: 100%;

            .its-paged-multi-select--listing {
                height: 100%;
            }
        }

        .its-panel--extend-right {
        }

        .its-filter-panel {
            min-width: 100%;
            overflow: hidden !important;

            .its-filter-panel--operands {
                margin-top: 10px;
                margin-left: 20px;

                md-menu {
                    .md-button {
                        text-transform: none;
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                        min-width: 0;

                        &:hover {
                            background-color: inherit;
                        }
                    }
                }
            }

            .its-filter-panel--toolbar {
                h3 {
                    color: white;
                }

                md-icon {
                    color: white;
                }
            }

            .its-filter-panel--content {
                max-height: 100%;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;

                .its-filter-panel--content--form {
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;

                    .its-filter-panel--content--form--template {
                    }

                    .its-filter-panel--content--paged-list {
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    .its-filter-panel--content--accordion-tree {
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 20px;
                        padding-top: 10px;
                    }

                    .its-filter-panel--content--number {
                        padding-left: 50px;
                        padding-right: 20px;

                        .its-filter-panel--content--number--unit-of-measure {
                            padding-left: 5px;
                            margin-bottom: 15px;
                        }
                    }

                    .its-filter-panel--content--text {
                        padding-left: 50px;
                        padding-right: 20px;
                        padding-bottom: 20px;
                    }

                    .its-filter-panel--content--audit-critical-fail {
                        padding-left: 50px;
                        padding-right: 20px;
                        padding-bottom: 20px;

                        label {
                            margin-bottom: 10px;
                        }
                    }

                    .its-filter-panel--content--select-list {
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    .its-filter-panel--content--multi-select-list {
                        margin-top: 20px;
                        padding-right: 20px;
                        padding-left: 50px;

                        &.full-panel {
                            padding-left: 20px;
                        }
                    }

                    .its-filter-panel--content--custom {
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    .its-filter-panel--content--form--no-results {
                        color: RGBa(0, 0, 0, 0.7);
                        margin-top: 24px;
                    }

                    .its-filter-panel--content--form--loading {
                        margin-top: 24px;
                    }
                }

                .its-filter-panel--radio-group {
                    padding-left: 20px;
                    padding-top: 20px;
                }

                .its-filter-panel--content--dates {
                    margin-left: 51px;

                    .its-filter-panel--content--dates--date {
                        height: 20px;
                        margin-bottom: 10px;
                        margin-top: 10px;

                        md-icon {
                            font-size: 1.45em;
                            position: relative;
                            top: 4px;
                            left: 10px;
                        }

                        .its-filter-panel--content--dates--date--label {
                            margin-right: 10px;
                            width: 35px;
                        }

                        .its-filter-panel--content--dates--date--value {
                            border-bottom: 1px solid #ddd;
                            width: 140px;
                            text-align: center;
                            color: rgba(0, 0, 0, 0.54);
                        }
                    }
                }
            }
        }
    }

    .its-panel {
        background-color: white;
        border-radius: 4px;
    }

    .its-filter-panel {
        min-width: 300px;
        overflow: hidden !important;

        .its-filter-panel--operands {
            margin-top: 10px;
            margin-left: 20px;

            md-menu {
                .md-button {
                    text-transform: none;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    min-width: 0;

                    &:hover {
                        background-color: inherit;
                    }
                }
            }
        }

        .its-filter-panel--paged-multi-select {
            max-height: 370px;

            .its-paged-multi-select--search-bar {
                height: 40px;

                md-input-container {
                    @extend .its-condense-top;
                    @extend .its-condense-bottom;
                    height: 40px;
                }
            }

            .its-paged-multi-select--listing {
                margin-top: 10px;
            }
        }

        .its-filter-panel--group-label {
            .its-accordion-list-item--label--text {
                font-weight: 600;
            }
        }

        .its-filter-panel--state-message {
            md-icon {
                color: rgba(0, 0, 0, 0.6);
                margin-bottom: 10px;
            }

            color: rgba(0, 0, 0, 0.6);
        }

        .its-panel--extend-right {
        }

        .its-filter-panel--toolbar {
            h3 {
                color: white;
            }

            md-icon {
                color: white;
            }
        }

        .its-filter-panel--action-item {
            font-weight: 500;
            color: $basePrimaryColour;
        }

        .its-filter-panel--content {
            max-height: 508px;
            min-height: 124px;
            overflow-x: hidden;
            overflow-y: auto;

            .its-filter-panel--content--item {
            }

            .its-filter-panel--content--form {
                width: 300px;
                max-width: 300px;
                min-width: 300px;

                .its-filter-panel--content--form--template {
                }

                .its-filter-panel--content--paged-list {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .its-filter-panel--content--accordion-tree {
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 20px;
                    padding-top: 10px;
                }

                .its-filter-panel--content--number {
                    padding-left: 50px;
                    padding-right: 20px;

                    .its-filter-panel--content--number--unit-of-measure {
                        padding-left: 5px;
                        margin-bottom: 15px;
                    }
                }

                .its-filter-panel--content--text {
                    padding-left: 10px;
                    padding-right: 0;
                    padding-bottom: 20px;
                }

                .its-filter-panel--content--audit-critical-fail {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 20px;

                    label {
                        margin-bottom: 10px;
                    }
                }

                .its-filter-panel--text-filter-value {
                    margin-top: 20px;
                    margin-bottom: 0;
                    height: 30px;

                    label {
                        visibility: hidden;
                    }
                }

                .its-filter-panel--text-filter-add-button {
                    margin-top: 20px;
                }

                .its-filter-panel--text-filter-delete-icon {
                    font-size: 1.5em;
                }

                .its-filter-panel--text-filter-delete-button {
                    padding-bottom: 0;
                    margin-bottom: -20px;
                }

                .its-filter-panel--content--select-list {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .its-filter-panel--content--multi-select-list {
                    margin-top: 20px;
                    padding-right: 20px;
                    padding-left: 50px;

                    &.full-panel {
                        padding-left: 20px;
                    }
                }

                .its-filter-panel--content--custom {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .its-filter-panel--content--form--no-results {
                    color: RGBa(0, 0, 0, 0.7);
                    margin-top: 24px;
                }

                .its-filter-panel--content--form--loading {
                    margin-top: 24px;
                }
            }

            .its-filter-panel--radio-group {
                padding-left: 20px;
                padding-top: 20px;
            }

            .its-filter-panel--content--dates {
                margin-left: 51px;

                .its-filter-panel--content--dates--date {
                    height: 20px;
                    margin-bottom: 10px;
                    margin-top: 10px;

                    md-icon {
                        font-size: 1.45em;
                        position: relative;
                        top: 4px;
                        left: 10px;
                    }

                    .its-filter-panel--content--dates--date--label {
                        margin-right: 10px;
                        width: 35px;
                    }

                    .its-filter-panel--content--dates--date--value {
                        border-bottom: 1px solid #ddd;
                        width: 140px;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }
    }

    &.its-left-config-panel {
        max-height: 100%;
        height: 100%;
        padding: 0;
        background-color: $DarkWhite;
        width: 389px;
        top: 0px !important;
        left: 0px !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .md-panel {
        .its-panel {
            min-height: 430px;
            max-height: 430px;

            .its-filter-panel .its-filter-panel--content {
                max-height: calc(430px - 64px);
                height: auto;
                width: 100%;
            }
        }
    }
}
