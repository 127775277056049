.its-custom-chart-editor {
    #chartTitle {
        margin-top: 30px;
    }

    #chartTitle,
    #chartSubTitle {
        margin-bottom: 0;
    }

    .its-custom-chart-editor--left-panel {
        width: 400px;
        background-color: white;
        margin-right: 12px;
        padding-bottom: 20px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);

        .its-empty-state {
            margin-top: 20px;
            margin-bottom: 20px;

            .its-empty-state--text {
                text-align: center;
            }
        }

        md-divider {
            margin-top: 20px;
        }

        .its-accordion-list-item--expand-icon {
            min-width: 0;
        }
    }

    .its-custom-chart-editor--right-panel {
        background: white;
        border-radius: 3px;
        margin: 12px 12px 12px 0;

        &.add-margin-left {
            margin-left: 12px;
        }
    }

    .its-custom-chart-editor--data-warning {
        padding-left: 120px;
    }

    .its-custom-chart-editor--save-cancel {
        background-color: white;
        margin-top: 0;
        padding: 20px;
    }
}

.its-custom-chart-editor-dimension-color {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -25px;
    border: 1px solid #c8c4c8;

    &.empty {
        background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
            linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%),
            linear-gradient(-45deg, transparent 75%, #eee 75%);
        background-size: 10px 10px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    }
}

.its-image-chart-settings-component--image-upload {
    .its-image-chart-settings-component--image-upload--file-picker {
        padding: 0;
        margin: auto;
        margin-bottom: 25px;

        .md-button {
            opacity: 0.8;
        }
    }

    .its-image-chart-settings-component--image-upload--photo {
        position: relative;
        margin-bottom: 40px;

        img {
            max-width: 100%;
            max-height: 400px;
        }

        .its-image-chart-settings-component--image-upload--photo--placeholder {
            background-color: $backgroundLightGrey;
            height: 300px;
            width: 100%;
            max-width: 600px;
            padding: 10px;
            line-height: 1.4;

            .its-image-chart-settings-component--image-upload--photo--placeholder-contents {
                i {
                    color: #ccc;
                    font-size: 5em;
                }

                span {
                    text-align: center;
                    color: #ccc;
                    font-size: 1.6em;
                    font-weight: 400;
                }
            }
        }
    }
}

.its-custom-chart-editor-component {
    padding: 20px;

    .its-custom-chart-editor-component--header {
    }

    .its-custom-chart-editor-component--config-options {
    }

    .its-custom-chart-editor-component--content {
        .its-custom-chart-editor-component--froala {
            label {
                margin-bottom: 10px;
            }
        }

        .its-custom-chart-editor-component--content--empty-state {
            color: rgba(0, 0, 0, 0.4);
            font-weight: 500;
        }
    }

    .its-custom-chart-editor-component--content--filter {
        md-select {
            margin: 0;
        }

        md-input-container {
            margin: 0;
        }
    }
}

.its-custom-chart-editor-component-preview {
    @extend .its-custom-chart-editor-component;
    padding: 0;

    .its-custom-chart {
        box-shadow: none;
    }
}

.its-chart-settings-data-settings-component {
    margin-bottom: 10px;
}

.its-chart-settings-filters-component {
    margin-bottom: 10px;
    margin-top: 10px;
}

.its-chart-settings-filters-component--and-or-buttons {
    margin-left: 20px;
    margin-top: 20px;

    .md-button {
        min-width: 60px;
        max-width: 60px;
        width: 60px;
        padding: 0;
        margin: 0;
        height: 30px;
        background-color: #ddd;
        color: rgba(0, 0, 0, 0.4);
        border-radius: 0;

        &[disabled='disabled'] {
            background-color: #3f9ecc;
            color: #fff;
        }
    }
}

.its-chip {
    .its-chip--button {
        min-height: 18px;
        min-width: 18px;
        max-height: 18px;
        max-width: 18px;
        color: $FontColour;
        margin: 0;
        padding: 0;
        line-height: 1em;
    }

    md-select {
        margin: 0 7px 0 0;
        padding-right: 5px;
        border-right: 1px solid $FontColour;
    }

    md-select:not([disabled]):focus {
        .md-select-value {
            border-bottom: none;
        }
    }

    md-select-value {
        border-bottom: none;
        min-height: 22px;
        max-height: 22px;
        padding: 0;

        .md-text {
            @extend .its-text--small;
        }

        span {
            position: relative;
            top: -1px;
        }
    }

    .md-select-icon {
        @extend .its-chip--icon;
        transform: scale(0.8);
    }
}

.its-custom-chart-data-point-chip {
    min-height: 34px;
    border: 1px solid $FontColour;

    &.its-custom-chart-data-point-chip--config-options {
        border-top: none;
    }

    .its-color-box {
        background-color: rgba(108, 117, 125, 0.5);
        width: 30px;
        max-width: 30px;
        min-width: 30px;

        .its-icon--small {
            color: $White;
        }
    }

    .its-inline-edit {
        position: relative;

        .its-inline-edit--icon {
            position: absolute;
            right: 0;
            top: 0;
            background-color: white;
        }

        input {
            height: 25px;
            line-height: 25px;
            padding-bottom: 0;
        }
    }

    .its-border-left {
        border-left: 1px solid $FontColour;
    }

    .its-border-right {
        border-right: 1px solid $FontColour;
    }

    .its-custom-chart-data-point-select {
        border: none;
        background-color: transparent;
        border-radius: 0;
        padding-left: 0;
        margin: 0;
        max-height: 30px;
        min-width: 82px;

        md-select-value {
            border-bottom: none;
            min-height: 22px;
            max-height: 22px;
            padding: 0;
            margin-top: 5px;

            .md-text {
                @extend .its-text--small;
            }

            span {
                position: relative;
                top: -1px;
            }
        }

        .md-select-icon {
            @extend .its-chip--icon;
            transform: scale(0.8);
        }
    }
}
