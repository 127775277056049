.its-document {
    height: 100%;

    @media print {
        background: white;
    }

    md-card {
        box-shadow: none;
        margin: 0;
        padding: 0;
    }

    .its-document--toolbar {
        padding-bottom: 10px;
        min-height: 58px;
    }

    .its-document--top {
        min-width: 40px;
        margin-left: 13px;
    }

    .its-document--content {
        background-color: white;
        margin: 0 12px 20px;
        overflow: auto;
        border-radius: 3px;

        @include media-breakpoint-down('sm') {
            margin: 0 0 20px 0;
            border-radius: unset;
        }
    }

    .its-document--edit-bar--desc {
        color: $White;
    }

    .its-document--content--organization-logo {
        padding: 0 20px 20px 20px;

        img {
            max-height: 90px;
            max-width: 480px;
        }
    }

    .its-document--section-heading {
        margin: 40px 0;
    }

    .its-document--section-divider {
        margin-bottom: 20px;
    }

    &.its-document-condensed .its-document--content--organization-logo {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &.its-document-condensed .its-document--content {
        padding: 0;
    }

    .its-document--header {
        margin-bottom: 20px;
        padding: 40px 20px;
        background-color: #f6f6f6;

        .its-document--title {
            md-icon {
                padding-bottom: 5px;
                padding-right: 15px;
            }
        }

        .its-document--title--score {
            text-align: right;
            display: table;
            margin-top: 30px;

            .its-document--title--score-centered {
                vertical-align: middle;

                .its-document--title--percentage {
                    @extend .its-text--title;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .its-document--title--label {
                    @extend .its-text--title;
                    text-align: right;
                    margin-bottom: 5px;
                }
            }
        }

        .its-document--weather-section {
            min-width: 350px;
            min-height: 200px;

            @include media-breakpoint-down(sm) {
                min-width: 0;
            }
        }

        .its-document--title--weather {
            display: table;
            margin-top: 38px;
            margin-left: 35px;
        }

        .its-document--title-follow-up-count {
            @extend .its-text__strong;
            color: $DangerColour;
        }

        .its-document--section--metadata {
            margin-top: 5px;
            padding-left: 45px;
            line-height: 1.7em;

            @include media-breakpoint-down(xs) {
                padding-left: 0;
            }

            .its-document--section--metadata--item {
                opacity: 0.6;

                .its-document--section--metadata-datetime {
                    white-space: nowrap;
                    @include media-breakpoint-down(xs) {
                        white-space: normal;
                    }
                }
            }
        }
    }

    &.its-document-condensed .its-document--header {
        margin-bottom: 0;
        padding: 20px 20px;
    }

    .its-document--section {
        .its-document--section--answers {
            padding-left: 45px;
        }

        @media (max-width: 600px) {
            .its-document--section--answers {
                padding-left: 0;
            }
        }

        .its-document--section--title {
            @extend .its-text--title;
            margin-left: 40px;

            @include media-breakpoint-down('xs') {
                margin-left: 20px;
            }

            md-icon {
                color: black;
            }

            .md-icon-button {
                md-icon {
                    color: $basePrimaryColour !important;
                }
            }
        }

        .its-document--section--score {
            padding-right: 24px;
            margin-left: auto;
        }

        .its-document--section--score--edit--button {
            cursor: pointer;
        }

        .its-document--section--question-container {
            margin-left: 80px;
            break-inside: avoid;

            @include media-breakpoint-down('xs') {
                margin-left: 20px;
            }

            @media print {
                page-break-after: avoid;
            }
        }

        .its-document--section--question {
            margin-top: 5px;

            .its-document--section--question--critical-issue {
                @extend .its-text--caption;
                @extend .its-text__danger;

                margin-top: 5px;

                md-icon {
                    @extend .its-text__danger;
                    @include md-icon-size(20px);
                }
            }

            .its-document--section--question--tag-label {
                @extend .its-text--caption;
                text-align: center;
                padding-left: 5px;
                margin: 10px 10px 10px 0;
            }

            .its-document--section--question--tag {
                @include md-icon-size(15px);
                @extend .its-text__muted;
                margin-right: 10px;
            }

            .its-document--section--question--data {
            }

            .its-document--section--question--score-edit {
                cursor: pointer;
            }

            .its-document--section--question--score {
                @extend .its-text__muted;
                padding-right: 24px;
            }

            .its-document--section--question-info {
                margin-bottom: $Margin;
            }

            .its-document--section--question--responses {
                word-wrap: break-word;
                margin-top: 15px;
                margin-bottom: 12px;
            }

            .its-document--section--question--response-wrapper {
                position: relative;
            }

            .its-document--section--question--response-edit {
                @include md-icon-size(20px);
                cursor: pointer;
                margin-right: 10px;
            }

            .its-document--section--question--response-text {
                white-space: pre-line;
                margin: 0;
                padding-left: 12px;
                align-self: center;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .its-document--section--question--response {
                padding-left: 12px;
                align-self: center;
                line-height: 20px;

                &.its-document--section--question--response__data-removed {
                    font-style: italic;
                    color: #4a4a4a;
                }

                &::first-letter {
                    text-transform: capitalize;
                }
            }
        }

        .its-document--section-response-icon {
            margin: 0;
        }

        .its-document--section-temperature-icon {
            color: #707070;
            padding-top: 9px;
        }

        .its-document--section-ranking-response-icon {
            color: #666666;
            background: #e0e0e0;
            height: 20px;
            width: 20px;
            text-align: center;
            font-size: 14px;
            border-radius: 3px;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .its-document--section--question-score {
            margin-bottom: 20px;
        }

        .its-document--section--media {
            margin-top: 10px;

            .its-document--section--media--container {
                height: 80px;
                width: 80px;
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;
                vertical-align: middle;
                text-align: center;
            }

            .its-document--section--media--image {
                max-height: 80px;
                max-width: 80px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .its-document--section--question--comment {
        margin-top: 12px;
        margin-left: 32px;
        display: flex;
    }

    .its-document--section--question--comment-container {
        margin-left: 32px;
        margin-bottom: 12px;
        padding: 12px;
        background: #f6f6f6;
    }

    .its-document--section--question--attachment-container {
        margin-top: 12px;
    }

    .its-document--section--question--followup-container {
        margin-left: 32px;
        margin-bottom: 12px;
        padding: 12px 12px 0;
        background: #f6f6f6;

        .its-document--section--question--followup {
            padding-bottom: 12px;
        }
    }

    .its-document--section--question--comment--media-check {
        margin-left: 32px;
        padding: 12px;
        background: #f6f6f6;
        margin-bottom: 12px;
    }

    .its-document--section--question--comment--media {
        max-width: 80px;
        cursor: pointer;
    }

    .its-document--title--percentage-score {
        margin-top: 10px;
    }

    .its-document--section--question--attachments {
        .its-document--section--question--attachments--attachment {
            margin-right: 36px;

            .its-document--section--question--attachment-comment {
                @extend .its-text--caption;
                margin-top: 10px;
            }
        }

        .its-document--section--question--attachment--media {
            margin-top: 3px;
            margin-left: 5px;
            cursor: pointer;
        }

        .its-document--section--attachment--filename {
            padding-left: 12px;
            align-self: center;
            line-height: 20px;
        }
    }

    .its-document--sentiment {
        margin-left: 36px;
    }

    .its-document--sentiment-container {
        padding: 24px;
        margin-bottom: 20px;
        margin-top: 12px;
        background-color: #f6f6f6;

        md-icon {
            margin: 0;
        }
    }

    .its-document--sentiment-icon {
        @include md-icon-size(34px);
        margin-right: 12px;
    }

    .its-document--keyword-chip {
        border-radius: 16px;
        display: block;
        height: 32px;
        line-height: 32px;
        margin: 0 10px 20px 0;
        padding: 0 12px 0 12px;
        background: #f6f6f6;
        float: left;
        box-sizing: border-box;
        max-width: 100%;
        position: relative;
    }

    .its-document--sentiment-progress-bar-container {
        margin-top: 14px;
        margin-left: 12px;
    }

    .its-document--sentiment-progress-bar {
        background-color: #ccc;
        height: 8px;
        min-width: 250px;
    }

    .its-document--sentiment-progress-bar-negative,
    .its-document--sentiment-progress-bar-positive {
        width: 50%;
        height: 8px;
        float: left;
    }

    .its-document--sentiment-progress-bar-negative-pill {
        float: right;
        background-color: $DangerColour;
        height: 8px;
    }

    .its-document--sentiment-progress-bar-positive-pill {
        float: left;
        background-color: $PositiveColour;
        height: 8px;
    }

    .its-document--sentiment-progress-bar-score {
        text-align: right;
    }

    .its-document--video-container {
        max-width: 640px;
        margin-left: 36px;
    }

    .its-document--video {
        max-width: 100%;
        max-height: 400px;
    }
}

.its-document--sentiment-positive {
    color: $PositiveColour;
}

.its-document--sentiment-neutral {
    color: $WarnColour;
}

.its-document--sentiment-negative {
    color: $DangerColour;
}

.its-document--editable-part {
    background-color: #e3f6fd;
    border: 2px solid #1499e1;
    border-style: dashed;
    padding: 0.5rem;

    .its-document--section--question--response,
    .its-document--section--question--response-text {
        padding-left: 0 !important;
    }
}
