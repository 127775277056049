.its-event-trigger--conditions-configuration {
    margin-left: 10px;
    margin-right: 10px;
}

.its-trigger-action--remove-button {
    position: absolute;
    right: 0;
    top: 10px;
}

.its-upload-steps {
    .its-upload-steps--step {
        display: inline-block;
        padding: 20px;
        .its-upload-steps--step-num {
            display: inline-block;
            border-radius: 25px;
            width: 25px;
            height: 25px;
            background: $backgroundDarkGrey;
            border: 1px solid transparent;
            line-height: 25px;
            text-align: center;
            color: white;
            margin-right: 8px;
        }

        .its-upload-steps--step-text {
            display: inline-block;
        }
    }

    .its-upload-steps--step__active {
        .its-upload-steps--step-num {
            background: $PrimaryColour;
            border: 1px solid lighten($brandLightGray, 20%);
        }
    }
}

.its-upload-status {
    @include md-icon-size(36px);
    display: block;
    position: relative;
    margin: 0;
}

.its-upload__warning {
    color: $WarnColour;
}

.its-upload__error {
    color: $DangerColour;
}

.its-upload__successful {
    color: $PositiveColour;
}

.its-upload-feedback-box {
    max-height: 300px;
    overflow-y: scroll;
}

.its-trigger-recipient-type {
    min-width: 150px;
}

.its-trigger-schedule-day {
    width: 100%;
}

.its-trigger-schedule-time {
    width: 50%;
}

.its-settings-edit-external-programs {
    md-input-container {
        padding-right: 20px;
    }

    .its-settings-edit-external-programs--form {
        padding-bottom: 10px;
        background-color: $White;

        .its-settings-edit-external-programs--action-button-row {
            padding-right: 10px;
        }

        .its-settings-edit-external-programs--form--header {
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .its-settings-edit-external-programs--form--add-section {
            padding-left: 20px;
            padding-right: 20px;
        }

        .its-settings-edit-external-programs--form--content {
            .its-settings-edit-external-programs--form--content--scoring {
                padding-left: 20px;
            }

            .its-settings-edit-external-programs--form--content--empty-state {
                .its-empty-state {
                    margin-top: 0;
                }
            }

            .its-settings-edit-external-programs--form--content--sections {
                margin-bottom: 20px;

                .its-settings-edit-external-programs--form--content--sections--section {
                    border-bottom: 1px solid $BorderColour;
                }
            }
        }
    }
}

.pdf-option-box {
    height: 525px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .box > * {
        flex: 1 1;
    }
}

.iti-accordion {
    padding: 0 !important;
    min-height: 100% !important;

    .iti-accordion-toolbar {
        min-height: 50px;
        height: 50px;

        &.iti-accordion-toolbar--not-viewed {
            color: $FontColour_Muted;
            background-color: $LightGrey;
        }

        .iti-accordion-toolbar--icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #374050;
            color: white;
            text-align: center;
            vertical-align: middle;
            display: table-cell;

            &.iti-accordion-toolbar--icon-valid {
                background-color: #60ae58;
            }

            &.iti-accordion-toolbar--icon-invalid {
                background-color: #dc514c;
            }
        }

        md-icon {
            font-size: 34px;
            line-height: inherit;
            width: 34px;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;

            &.active {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
    }

    .iti-accordion-step {
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0 !important;
        -webkit-transition: max-height 0.25s ease-out;
        transition: max-height 0.25s ease-out;

        &.active {
            max-height: 800px !important;
            -webkit-transition: max-height 0.35s ease-in !important;
            transition: max-height 0.35s ease-in !important;
        }
    }
}
