.its-sub-menu {
    min-height: 57px;
    max-height: 57px;
    background: white;
    border-bottom: 1px solid #ccc;
    z-index: 3;

    .its-sub-menu--text {
        font-size: 0.85rem;
        font-weight: 400;
        text-transform: none;
        vertical-align: middle;
    }

    .its-sub-menu--icon {
        margin-right: 15px;
    }

    .its-sub-menu--datepicker {
        .its-sub-menu--datepicker--hide-xs {
            @media (max-width: 600px) {
                md-icon {
                    display: none;
                }
            }
        }

        .its-sub-menu--datepicker--icon-button {
            .md-button {
                margin-right: 0px;
                margin-left: 5px;
                padding-left: 10px;
                min-width: 25px;
            }
        }

        .its-sub-menu--datepicker--input {
            @media (max-width: 600px) {
                margin-left: -50px;
                margin-right: -20px;
            }
            @media (min-width: 600px) {
                margin-left: 20px;
            }
        }

        .md-datepicker-input-container {
            @media (max-width: 600px) {
                width: 50%;
            }
        }

        .md-datepicker-input[placeholder] {
            opacity: 0.75;
        }

        .its-sub-menu--datepicker--icon {
            opacity: 0.8;
        }

        .its-sub-menu--datepicker--cancel-icon {
            opacity: 0.5;
        }
    }
}

.its-sub-menu--list {
    max-height: 80vh;
}
