.its-numerical-chart {
    vertical-align: middle;
    opacity: 0.8;
    text-align: center;

    .its-numerical-chart--label {
        margin-top: 160px;

        .its-numerical-chart--label--percent {
            opacity: 0.2;
        }
    }

    .its-numerical-chart--secondary-label {
        margin-top: 80px;

        md-icon {
            font-size: 50px;
            line-height: 20px;
            padding-right: 10px;
        }
    }
}
