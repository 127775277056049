$tileWidth: 380px;
$tileHeight: 380px;
$overlayHeight: 100px;

.its-media-gallery {
    margin-bottom: 12px;
    background-color: $color-grey-100;

    h2 {
        padding: 20px;
        margin: 0;
    }

    .its-media-gallery--tile-container {
        padding: 0 8px;
    }
}
.its-media-gallery-tile {
    height: $tileHeight;
    width: $tileWidth;
    background-color: white;
    margin: 10px;
    position: relative;
    border-radius: 6px;
    border: 1px solid $color-grey-300;
    color: $color-grey-700;

    img {
        border-radius: 6px;
    }

    @media (max-width: 600px) {
        height: calc(#{$tileHeight} - 120px);
        width: calc(#{$tileWidth} - 120px);
        margin-left: 0;
        margin-right: 0;
    }

    &:hover {
        .its-media-gallery-tile--text-overlay {
            top: calc(#{$tileHeight} - #{$overlayHeight});
            height: $overlayHeight;
            z-index: 1;

            @media (max-width: 600px) {
                top: calc(#{$tileHeight} - #{$overlayHeight} - 120px);
            }
        }
    }

    .its-media-gallery-tile--text-overlay {
        position: absolute;
        top: $tileHeight;
        background-color: rgba(0, 0, 0, 0.4);
        height: 0px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        overflow: hidden;

        @media (max-width: 600px) {
            top: calc(#{$tileHeight} - 120px);
        }

        span {
            color: #fff;
            font-weight: 400;
            font-size: 15px;
            padding: 20px;
        }

        i {
            color: #fff;
            position: relative;
            top: 6px;
        }

        md-icon {
            color: #fff;
        }

        & div:last-of-type {
            margin-bottom: 10px;
        }

        .its-media-gallery--text-overlay-item {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .its-media-gallery-tile--audio-player {
        position: absolute;
        top: 1px;
        left: 80px;
        height: 60px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;

        @media (max-width: 600px) {
            top: 200px;
        }

        & audio {
            width: 175px;
            display: block;
            margin: 15px;
        }
    }

    .its-media-gallery-tile--media-header {
        position: absolute;
        top: 0;
        width: 100%;

        h2,
        md-icon {
            color: white;
        }
    }

    .its-media-gallery-tile--video {
        height: 360px;
        width: 100%;
        max-height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.clickable {
    cursor: pointer;
}
