.its-standard-dashboard--check {
    .its-btn-bar {
        background-color: $CheckColour_Primary;
    }

    // LEFT CONTENT
    .its-standard-dashboard--left-content {
        .its-standard-dashboard--data-table {
            .its-standard-dashboard--data-table--row:nth-child(odd) {
                background-color: $CheckColour_White;

                &:hover {
                    background-color: rgba(black, 0.1);
                }
            }
        }
    }

    // RIGHT CONTENT
    .its-standard-dashboard--right-content {
        background-color: $CheckColour_White;

        ite-tile-state {
            .its-tile-state {
                border: 2px dashed rgba($CheckColour_Light, 0.3);
            }
        }

        .its-standard-dashboard--chart-empty-state {
            border: 2px dashed rgba($CheckColour_Light, 0.3);
        }

        .its-chart--horizontal-percentage--bar-background {
            background-color: rgba($CheckColour_Primary, 0.075);
        }

        .its-standard-dashboard--data-table {
            .its-standard-dashboard--data-table--row:nth-child(odd) {
                background-color: rgba($CheckColour_Primary, 0.05);

                &:hover {
                    background-color: rgba(black, 0.1);
                }
            }
        }
    }

    .its-standard-dashboard--empty-state {
        background-color: $CheckColour_White;

        .its-standard-dashboard--empty-state--image-container {
            border-color: rgba($CheckColour_Light, 0.3);
        }
    }
}

//HIGHCHARTS OVERRIDES
.its-standard-dashboard--check {
    .highcharts-color-0 {
        fill: $CheckColour_Primary;
        stroke: $CheckColour_Primary;
    }

    #outcomesChart {
        .highcharts-plot-background {
            stroke-width: 0;
        }
    }
}

//LOADING OVERRIDE
.its-standard-dashboard--check {
    md-progress-circular {
        path {
            stroke: $CheckColour_Primary;
        }
    }
}

//BUTTON OVERRIDES
.its-standard-dashboard--check {
    .its-btn--primary {
        background: $CheckColour_Primary;

        &:not([disabled]):hover {
            background-color: darken($CheckColour_Primary, 10%);
        }
    }

    .its-btn--text {
        color: $CheckColour_Primary;

        .its-btn--icon {
            color: $CheckColour_Primary;
        }
    }

    .its-btn-bar {
        background-color: $CheckColour_Primary;
    }
}

//SCROLLBAR OVERRIDES
.its-standard-dashboard--check {
    .its-standard-dashboard--left-content,
    .its-standard-dashboard--right-content {
        &::-webkit-scrollbar-track {
            background: $CheckColour_White;
        }

        &::-webkit-scrollbar-thumb {
            background: $CheckColour_Primary;
        }
    }
}

.its-button-colour-check {
    color: $CheckColour_Primary;
    opacity: 0.7;

    md-icon {
        color: $CheckColour_Primary;
    }
}
