.its-custom-report-editor {
    .its-custom-report-editor--left-panel {
        width: 400px;
        background-color: white;
        margin-right: 12px;
        padding-bottom: 20px;

        .its-empty-state {
            margin-top: 20px;
            margin-bottom: 20px;

            .its-empty-state--text {
                text-align: center;
            }
        }

        .its-accordion-list-item--expand-icon {
            min-width: 0;
        }
    }

    .its-custom-report-editor--right-panel {
        background: white;
        border-radius: 3px;
        margin: 0px 12px 12px 0;

        &.add-margin-left {
            margin-left: 12px;
        }
    }

    .its-custom-report-editor--data-warning {
    }

    .its-custom-report-editor--save-cancel {
        background-color: white;
        margin-top: 0;
        padding: 20px;
    }

    .its-custom-report--columns {
        padding-bottom: 20px;

        .its-custom-report--column-container {
            min-height: 48px;

            .its-custom-report--column-backdrop {
                height: 48px;
                padding: 0 10px;
                margin: 5px 10px 10px 0;

                .its-custom-report--column-chip {
                    background-color: $basePrimaryColour;
                    color: $textOverProductColor;
                    border-radius: 16px;
                    display: block;
                    height: 32px;
                    padding: 0 12px 0 12px;
                    font-size: 16px;
                    max-width: 220px;

                    .its-custom-report--column-chip-label {
                        margin: 8px 0px 0 5px;
                        text-overflow: ellipsis;
                    }

                    md-menu {
                        md-icon {
                            color: $textOverProductColor;
                            height: 18px;
                            width: 18px;
                        }

                        button {
                            padding: 0 0 4px 0;
                            width: 24px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }

    .its-custom-report-editor-component {
        padding: 0 20px 20px 20px;
    }

    .its-custom-report--formatting-container {
        padding: 15px 10px 0 0;
        margin: 5px 0;
    }

    .its-custom-report--reorder-buttons {
        margin-left: -30px;
        margin-right: 5px;

        .its-custom-report--reorder-button {
            margin: 0 !important;
            padding: 0 !important;
            height: 25px;
            min-width: 0;
            line-height: 24px;
            width: 25px;
            border-radius: 50%;
        }
    }
}

.its-custom-report--header {
    min-height: 50px;
    max-height: 50px;
    @media print {
        display: none;
    }

    md-menu {
        margin-right: 15px;
    }
}

.its-custom-report--datalist-width {
    display: inline-table;
    min-width: 100%;

    .its-custom-report--datalist-searchbar {
        height: 66px;
    }
}

.its-searchbar--container-searching {
    .vertical-divider {
        height: 66px;
    }
}

.vertical-divider {
    border-top: 0;
    border-right: 1px solid grey;
    height: 100%;
    margin: 0 10px;
}

.its-custom-report--search-column-container {
    padding: 5px 5px 5px 5px;
}

.allow-text-wrap {
    .md-truncate {
        overflow: inherit;
        white-space: inherit;
        text-overflow: inherit;
        word-break: break-word;
    }
}

.its-column-chip-component-chip {
    min-height: 32px;
    border: 1px solid $FontColour;

    .its-inline-edit {
        position: relative;

        .its-inline-edit--icon {
            position: absolute;
            right: 0;
            top: 0;
            background-color: white;
        }

        input {
            height: 25px;
            line-height: 25px;
            padding-bottom: 0;
        }
    }

    .its-border-left {
        border-left: 1px solid $FontColour;
    }
}
