.its-integration-list-container {
    margin: 0 auto;
    max-width: 750px;
}

.its-integration-list-card {
    @extend .its-margin-left__none, .its-margin-right__none;
    padding: 0;

    @media (max-width: 600px) {
        text-align: center;
    }
}

.its-integration-list-info {
    @extend .its-padding-sides;
    padding-top: 24px;
    padding-bottom: 24px;
}

.its-integration-list-icon {
    margin: 0 24px 0 $Margin;
    width: 24px;

    @media (max-width: 600px) {
        margin: 0 auto 24px auto;
    }
}

.its-integration-connections-count {
    @extend .its-clickable, .its-border-top;
    text-align: center;
    padding: 6px 0;
    color: $PrimaryColour;
}

.its-integration-connection {
    @extend .its-padding, .its-border-top;
    padding-right: 0;
}

.its-integration-connection-name {
    @extend .its-text--subtitle;
    font-weight: normal;
}

.its-integration-icon {
    margin-right: $Margin;
}

.its-oauth-button {
    padding: 12px 24px 12px 48px;
    border: 0 none;
}

.its-dropbox-integration-settings .its-oauth-button {
    background: #499fdd url('/assets/images/integrations/dropbox.jpg') 10px 9px no-repeat;
    color: white;
}

.its-box-integration-settings .its-oauth-button {
    background: #0061d5 url('/assets/images/integrations/box-white.png') 14px 12px no-repeat;
    color: white;
}

.its-gdrive-integration-settings .its-oauth-button {
    background: #ffffff url('/assets/images/integrations/gdrive.png') 13px 8px no-repeat;
    color: inherit;
    border: 1px solid #d2d4e1;
    border-radius: 4px;
}
