.its-tag-button {
    margin-top: 5px;
}

.its-color-inherit-override {
    color: inherit !important;
}

.its-text-align-center {
    text-align: center;
}

.its-trigger-condition-label {
    margin-top: 26px;
    font-size: 15px;
    padding-right: 5px;
}
