form {
    md-content {
        /* needed due to a bug in material select overflow (https://github.com/angular/material/issues/6312) */
        .md-select-value *:first-child {
            flex: 1 1 auto;
        }
    }
}

.its-form--select-clear-btn {
    position: absolute;
    top: 0px;
    right: 15px;

    md-icon {
        font-size: 16px;
        padding-top: 2px;
        color: rgba(0, 0, 0, 0.38) !important;
    }
}
