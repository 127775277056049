.its-attachment-modal--subheader {
    font-size: 1em;
    opacity: 0.7;
    font-weight: 400;
}

.its-image-info-modal--media-queries {
    @media (min-height: 1200px) {
        min-height: 1100px;
        max-height: 1100px;
        height: 70vh;
    }

    @media (min-height: 1000px) and (max-height: 1199px) {
        min-height: 900px;
        max-height: 900px;
        height: 60vh;
    }

    @media (min-height: 800px) and (max-height: 999px) {
        min-height: 700px;
        max-height: 700px;
        height: 60vh;
    }

    @media (min-height: 600px) and (max-height: 799px) {
        min-height: 500px;
        max-height: 500px;
        height: 50vh;
    }

    @media (max-height: 599px) {
        min-height: 500px;
        max-height: 500px;
        height: 50vh;
    }
}

.its-attachment-modal--image {
    @extend .its-image-info-modal--media-queries;

    background-color: #000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
}

.its-attachment-modal--comment {
    @extend .its-attachment-modal--subheader;
}
