.its-button-group {
    margin: 0;
    padding: 3px 15px 3px 15px;
    color: rgb(49, 46, 46);
    background-color: rgba(224, 224, 224, 0.96) !important;
    text-transform: none;
    font-weight: 400;
    min-width: 100px;
    border-radius: 0;

    &.left {
        border-right: 1px solid rgba(230, 230, 230, 0.96);
    }

    &.middle {
        border-right: 1px solid rgba(230, 230, 230, 0.96);
    }

    &.right {
    }

    // important to overwrite some stupid md functionality
    &.selected {
        background-color: rgba(193, 193, 193, 0.96) !important;
    }

    &:not([disabled]):hover {
        background-color: rgba(193, 193, 193, 0.96);
        transition: 0.3s;
    }
}
