.primary-comparate--header {
    color: white;
    background-color: $WarnColour;
}

.primary-comparate--light {
    background-color: #fffcf4;
}

.primary-comparate--dark {
    background-color: #fef8e9;
}

.primary-comparate--legend-swatch {
    background-color: $WarnColour;
    height: 16px;
    width: 16px;
}

.secondary-comparate--header {
    color: white;
    background-color: $PrimaryColour;
}

.secondary-comparate--light {
    background-color: #f6fcfe;
}

.secondary-comparate--dark {
    background-color: #edf9fd;
}

.secondary-comparate--legend-swatch {
    background-color: $PrimaryColour;
    height: 16px;
    width: 16px;
}

.item-cell--dark {
    background-color: #fafafa;
}

.bar-metrics {
    .its-chart--horizontal-percentage {
        margin-bottom: 0;
    }
}

.min-width-100 {
    min-width: 100px;
}

ite-metric-bar {
    &.primary-comparate--fill-colour {
        .its-metric-bar--bar-fill {
            background-color: $WarnColour !important;
            background-image: none;
        }
    }
    &.secondary-comparate--fill-colour {
        .its-metric-bar--bar-fill {
            background-color: $PrimaryColour !important;
            background-image: none;
        }
    }
}
