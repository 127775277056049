.its-program-card--header__title {
    font-weight: 500;
    font-size: 1.2em;
}

.its-program-card--loader {
    margin-top: 110px;
    margin-bottom: 115px;
}

.its-program-card--header__product {
    padding-top: 5px;
    opacity: 0.4;
}

.its-program-card--header-score {
    line-height: 1;
    text-align: right;

    .its-program-card--header-score__score {
        font-size: 2em;

        span {
            font-weight: 100;
        }
    }

    .its-program-card--header-score__score-text {
        font-weight: 500;
        opacity: 0.3;
    }
}

.its-chart--hidden {
    visibility: hidden;
    opacity: 0;
}

.its-chart--visible {
    visibility: visible;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    opacity: 1;
}

.its-chart--remove--loader {
    display: none;
}

.its-chart--loader {
    margin-top: 140px;
    margin-bottom: 140px;
}

// needed for the high of charts

.highcharts-container {
    width: 100% !important;
    height: 100% !important;
}

.highcharts-container > svg {
    width: 100% !important;
}

.its--chart--header {
    text-align: center;
    padding-top: 5px;
}

.its--chart--sub-header {
    font-weight: 400;
    padding-top: 3px;
    font-size: 0.95em;
    color: #aaa;
}
