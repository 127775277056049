.its-standard-dashboard {
    ite-program-picker {
        @media (max-width: 600px) {
            max-width: 150px;
            min-width: 150px;
        }
        max-width: 300px;
        min-width: 300px;
    }

    .its-google-map {
        overflow: hidden !important;
    }

    .its-hide-right-y-axis-gridlines {
        .highcharts-container {
            .highcharts-root {
                g:nth-child(9) {
                    stroke-width: 0;
                }
            }
        }
    }

    md-input-container label:not(.md-container-ignore),
    md-input-container label {
        position: absolute;
    }

    .its-btn-bar {
        h3 {
            margin-left: 20px;
            color: white;
        }

        ite-program-data-content-filter {
            color: white;
        }

        ite-paged-search-select {
            .ite-search-select--selection {
                margin: 0;

                label {
                    margin-left: $Margin_half;
                    font-size: 14px;
                    line-height: 26px;
                    transform: translate3d(0, 32px, 0);
                }

                &.md-input-has-value {
                    label {
                        display: none;
                    }
                }

                .its-icon-btn {
                    transform: translate3d(-2px, -16px, 0);
                }
            }
        }
    }

    .its-standard-dashboard--chart-container {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 20px;
        }
    }

    .its-chip--float-danger {
        @extend .its-chip--float;
        background-color: $DangerColour;
    }

    .its-standard-dashboard--highchart-container {
        margin-bottom: 20px;
        h3 {
            margin-left: 30px;
            margin-bottom: 20px;
        }

        &.its-print--reduce-margin {
            @media print {
                h3 {
                    margin-bottom: 0;
                }

                margin-bottom: 0;
            }
        }

        .its-standard-dashboard--highchart-container--legend-container {
            .its-legend-item {
                padding: 0 10px 0 10px;

                .layout-row {
                    margin-right: 3px;
                }

                .bullet {
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                .label {
                    color: rgba(36, 36, 36, 0.5);
                    transition: all ease 300ms;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
    }

    //DATA TABLES
    .its-standard-dashboard--data-table {
        margin-bottom: 30px;

        h3 {
            padding-left: 30px;
            margin-bottom: 10px;
        }

        .its-standard-dashboard--data-table--headers {
            padding: 12px 30px;
            border-bottom: 1px solid $BorderColour;

            .its-header {
                @extend .its-text--caption;
                @extend .its-text__strong;
                @extend .its-text__uppercase;
                color: $FontColour_Light;
            }

            .its-header-right {
                @extend .its-header, .align-right, .text-right;
            }
        }

        .its-standard-dashboard--data-table--row {
            padding: 12px 30px;
            cursor: pointer;
            transition: background-color cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s;

            .its-data-cell {
                @extend .its-text__strong;
                color: $FontColour_Muted;
            }

            .its-data-cell-right {
                @extend .its-data-cell, .align-right, .text-right;
            }

            &:nth-child(odd) {
                background-color: $IntelligenceColour_White;
            }

            &:hover,
            &:nth-child(odd):hover {
                background-color: rgba(black, 0.1);
            }
        }
    }

    .its-standard-dashboard--single-value {
        margin-bottom: 30px;

        h3 {
            text-align: center;
            margin: 0 10px 20px 10px;
        }

        p {
            margin: 0;
            font-size: 42px;
            font-weight: 300;
            line-height: initial;
            letter-spacing: 1px;
            color: $FontColour;
            position: relative;
            text-align: center;
        }
    }

    //EMPTY STATE
    .its-standard-dashboard--empty-state {
        .its-standard-dashboard--empty-state--image-container {
            width: 420px;
            height: 230px;
            border-radius: 5px;
            margin-bottom: 10px;
            border: 2px dashed $PrimaryColour;
            background-color: rgba(white, 0.3);

            @media (max-width: 600px) {
                width: 300px;
                height: 165px;

                img {
                    width: inherit;
                    height: inherit;
                }
            }
        }

        h2 {
            color: $FontColour;
            font-size: 16px;
            font-weight: 500;
            margin: 10px 0 0 0;
        }

        p {
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.05em;
            text-align: center;
            max-width: 400px;
        }
    }

    .its-standard-dashboard--single-value-small-text {
        @extend .its-standard-dashboard--single-value;

        p {
            font-size: 34px;
        }
    }

    md-content {
        &.its-standard-dashboard--content {
            @include media-breakpoint-up('md') {
                overflow-y: hidden;
            }
        }
    }

    //LEFT CONTENT
    .its-standard-dashboard--left-content {
        padding: 20px 0 0;
        background: $White;
    }

    //RIGHT CONTENT
    .its-standard-dashboard--right-content {
        background-color: $IntelligenceColour_White;
        padding: 20px 0;
        border-left: none;
        border-top: 1px solid $BorderColour;
        break-before: always;
        page-break-before: always;

        ite-tile-state {
            max-height: 200px;

            .its-tile-state {
                border-radius: 3px;
                padding: 20px 20px 15px;
                border: 2px dashed rgba($PrimaryColour, 0.3);

                md-icon {
                    font-size: 24px;
                    height: 24px;
                    width: 24px;
                    min-height: 24px;
                    min-width: 24px;
                }

                .its-tile-state--text {
                    font-size: 15px;
                    line-height: 18px;
                    color: rgba(36, 36, 36, 1);
                }
            }
        }

        @include media-breakpoint-up('md') {
            min-width: 350px;
            max-width: 350px;
            border-left: 1px solid $BorderColour;
        }

        .its-standard-dashboard--highchart-container {
            .its-standard-dashboard--chart-empty-state {
                margin: 30px;
            }
        }

        .its-standard-dashboard--single-value {
            margin-bottom: 30px;

            .its-standard-dashboard--chart-empty-state {
                p {
                    font-weight: 400;
                    letter-spacing: 0;
                }
                margin: 0 30px;
            }

            h3 {
                margin-left: 30px;
                text-align: left;
            }

            p {
                width: 100%;
            }
        }

        .its-standard-dashboard--single-value-small-text {
            @extend .its-standard-dashboard--single-value;

            p {
                font-size: 34px;
            }
        }

        .its-standard-dashboard--data-table {
            .its-standard-dashboard--data-table--row:nth-child(odd) {
                background: rgba($PrimaryColour, 0.05);
            }

            .its-standard-dashboard--chart-empty-state {
                margin: 30px 30px 0;
            }

            ite-tile-state {
                margin-bottom: 30px;
                padding: 0 30px;
                width: auto;
            }
        }

        .its-standard-dashboard--chart-container {
            margin-bottom: 30px;
            padding: 0 12px;

            .its-standard-dashboard--map-location-info {
                margin-bottom: 10px;
                line-height: 16px;

                .its-strong {
                    font-weight: 500;
                    font-size: 16px;
                }

                div {
                    margin-bottom: 5px;
                }
            }
        }

        .its-standard-dashboard--chart-empty-state {
            border-radius: 3px;
            padding: 20px 20px 15px;
            background-color: rgba(white, 0.4);
            border: 2px dashed rgba($IntelligenceColour_Light, 0.3);

            .its-btn--text {
                margin: 0;
            }

            p {
                text-align: center;
                font-size: 15px;
                line-height: 18px;
                margin: 0 0 10px;
                color: $FontColour_Light;
            }
        }
    }
}

.its-standard-dashboard--chip-container {
    position: relative;

    .its-chip--float {
        top: -20px;
        right: -20px;
        cursor: default;
    }
}

.its-chart--horizontal-percentage {
    margin-bottom: 20px;

    h4 {
        min-height: 18px;
        padding-right: 30px;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
        color: $FontColour_Muted;
    }

    p {
        color: $FontColour_Muted;
        font-weight: 500;
        margin: 0 0 0 10px;
        font-size: 12px;
        line-height: 12px;
    }

    .its-chart--horizontal-percentage--bar-background {
        position: relative;
        height: 9px;
        border-radius: 5px;
        background-color: darken($IntelligenceColour_White, 5);

        .its-chart--horizontal-percentage--bar-fill {
            position: absolute;
            height: 9px;
            left: 0;
            border-radius: 5px;
            width: 150px;
        }

        .red-gradient {
            background-image: linear-gradient(to right, $RedGradient);
        }

        .yellow-gradient {
            background-image: linear-gradient(to right, $YellowGradient);
        }

        .green-gradient {
            background-image: linear-gradient(to right, $GreenGradient);
        }
    }
}

.its-standard-dashboard--left-content,
.its-standard-dashboard--right-content {
    @include media-breakpoint-up('md') {
        overflow-y: scroll;
    }
}

//SCROLLBAR
.its-standard-dashboard {
    .its-standard-dashboard--left-content,
    .its-standard-dashboard--right-content {
        &::-webkit-scrollbar {
            display: flex;
            width: 2px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: $PrimaryColour;
        }
    }

    .highcharts-plot-background {
        stroke: none;
    }
}

.its-blurb {
    padding: $Padding_half $Padding;
    font-weight: $FontStrong;
    color: $FontColour-75;
    box-shadow: $MenuShadow;
    max-width: 250px;
    .its-icon {
        margin-right: $Margin_half;
    }
}
