.its-table-list {
    padding: 0;

    .its-table-list--table {
        padding: 2%;

        md-list {
            overflow: scroll;
        }

        .its-table-list--table--header {
            text-transform: uppercase;
            color: #ccc;
            font-size: 0.9em;

            .its-table-list--table--header--sortable i {
                vertical-align: middle;
                padding-bottom: 0px;
                &:hover {
                    color: #888;
                }
            }
        }

        .its-table-list-table--cell {
            padding-right: 20px;
        }
    }
}

.its-table-list-loading__lowered {
    margin-top: 300px;
}

.its-content-selector {
    margin-top: -20px;
}

.icon__inactive {
    opacity: 0.2;
}

.its-with-create {
    margin-bottom: 100px;
}
