.its-content--container {
    display: flex;
    justify-content: center;
}

.its-content {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    margin: 32px;
}

.iti-icon-count-container {
    position: relative;

    .iti-icon-count-item {
        font-size: 10px;
        position: absolute;
        background-color: $basePrimaryColour;
        border-radius: 10px;
        color: #ffffff;
        font-weight: 500;
        height: 16px;
        width: auto;
        max-width: 22px;
        left: 5px;
        top: 7px;
        z-index: 1;
        padding: 0 5px;
    }
}

.no-max-width {
    max-width: none !important;
}
