@media (max-width: 960px) {
    #its-topbar {
        .its-toolbar {
            > button {
                min-width: 42px;
            }

            > div {
                overflow: hidden;

                h2 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                div[ng-if='vm.organizationLogoUrl'] {
                    display: none;
                }
            }
        }
    }

    .its-trial-banner {
        display: none;
    }
}

.its-toolbar--refresh-icon {
    margin: 0 24px 0 15px !important;
}

.its-trial-banner {
    position: fixed;
    z-index: 10000;
    top: 8px;
    left: 30%;
    background: #fff;
    border-radius: 5px;
    opacity: 0.8;
    font-size: 0.9em;
    padding: 8px 20px;
}

.its-trial-banner--button {
    padding-left: 5px;
}

body {
    background-color: #eee;
}

@media screen and (min-width: 600px) {
    .its-iq-content-container {
        padding: 0 20px 20px;

        md-select {
            margin: 0;
        }
    }
    .its-iq-content-container-pdf {
        padding: 0;
    }
}

.its-iq-content-with-subnav {
    margin-top: 65px;
}

.its--chart-container {
    background-color: #fff;
    margin: 10px 10px 20px;
    max-height: 410px;
    min-height: 410px;
    height: 410px;
    overflow: hidden;
    border-radius: 3px;
}

@media (max-width: 960px) {
    .its--chart {
        min-height: 89%;
        padding: 5px;
    }
}

.md-default-theme.md-hue-3 .md-datepicker-input,
.md-hue-3 .md-datepicker-input {
    color: rgb(33, 33, 33);
    background: rgba(255, 255, 255, 0.87);
}

.its-range-select--ok {
    color: rgb(63, 158, 204) !important;
}

.its-toolbar--icon {
    color: #2c2c2c;
}

.its-toolbar--icon-tags {
    margin-right: 8px;
}
