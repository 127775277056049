@media print {
    * {
        overflow: visible !important;
        -webkit-transition: none !important;
        transition: none !important;
        position: relative;
    }

    .print-hide {
        display: none;
    }

    #its-sidebar,
    #its-topbar,
    .its-sub-menu {
        display: none;
    }
    .its-iq-content-with-subnav {
        margin: 0;
    }
    #its-content {
        padding: 0;
    }
    .its-sidebar--padding {
        margin: 0;
    }

    .its-iq-content-with-subnav {
        margin: 0;
        padding: 0;
    }

    .its-filters-select {
        margin: 0;
        display: none;
    }

    .its-document--content {
        box-shadow: none !important;
    }

    .its-document--section md-list {
        padding: 0 !important;
        margin: 0;
    }

    .its-document .its-document--header {
        padding: 20px;

        .its-document--header-container {
            display: table;
            width: 100%;
        }

        .its-document--title-section {
            display: table-cell;
        }

        .its-document--title--score-section {
            display: table-cell;
            text-align: right;
        }

        .its-document--title--score {
            display: block;
            .its-document--title--score-centered {
                display: block;
            }
        }
    }

    .its-table md-list-item {
        min-height: inherit;
    }

    .its-table-data {
        box-shadow: none !important;
        padding: 0;
        margin: 0;

        .its-records-question-container .its-table span {
            padding: 0;
            word-wrap: break-word;
            white-space: normal;
        }

        md-list-item.its-table--row {
            font-weight: 200;
            break-inside: avoid;
            height: auto;

            &::before {
                min-height: 30px;
            }
        }
    }

    .its-document {
        height: inherit;

        .its-document--section .its-document--section--question {
            margin: 0;
            display: table;
            width: 100%;
        }

        .its-document--section .its-document--section--score {
            line-height: 1.5;
            -webkit-margin-before: 1em;
            -webkit-margin-after: 1em;
            display: table-cell;
            text-align: right;
        }

        .its-document--section .its-document--section--title {
            display: table-cell;
        }

        .its-document--section .its-document--section-heading {
            display: table;
            width: 100%;
        }

        .its-document--section .its-document--section--question .its-document--section--question-info {
            display: table-cell;
        }

        .its-document--section .its-document--section--question .its-document--section--question--responses {
            padding-bottom: 5px;
        }

        .its-document--section .its-document--section--question .its-document--section--question--label {
            font-weight: 400;
            color: #4a4a4a;
        }

        .its-document--section .its-document--section--question .its-document--section--question--avg-score {
            display: inline-block;
        }

        .its-document--section .its-document--section--question .its-document--section--question--data {
            display: table-cell;
            text-align: right;
        }

        .its-document--section
            .its-document--section--question
            .its-document--section--question--attachments
            .its-document--section--question--attachments--media-container {
            display: inline-block;
            break-inside: avoid-page;

            .its-document--section--question--comment--media-check {
                display: inline-block;
                break-inside: avoid;

                .its-document--section--question--attachment--media,
                img {
                    display: inline-block;
                    break-inside: avoid;
                }
            }
        }

        .its-document--section .its-document--section--question {
            .its-document--section--question--comment-container {
                display: inline-block;
                break-inside: avoid;

                .its-document--section--question--comment-comment {
                    break-inside: avoid;
                }
            }

            .its-document--section--question--followup-container {
                display: block;
                break-inside: avoid;
            }
        }
    }

    .full-height {
        height: inherit;
    }
    .its-custom-chart:not(.its-datatable-chart) {
        display: inline-flex;
        width: 100%;
        //height: auto !important;
        //min-height: auto !important;
        its-datalist-cell > .its-datalist--cell-text > span {
            overflow: auto !important;
        }
        md-content {
            overflow: hidden !important;
        }
    }

    .its-datatable md-list-item {
        max-height: none !important;
    }
    .its-datalist--header {
        height: auto;
        .its-datalist--header-text {
            white-space: break-spaces;
        }
    }
}
