.its-comments-dashboard.its-print-view {
    margin-left: -1.08in;
    margin-right: -0.5in;

    .its-container {
        margin-right: 0;
        border-radius: 0;
    }

    .its-btn-bar {
        padding-left: 6px;
    }

    md-content {
        background: white;
        padding: 0;

        h3 {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 4px;
        }

        .its-margin-top {
            margin-top: 0;
        }

        .its-margin-bot__half,
        .its-margin-bot {
            margin-bottom: 0;
        }

        .its-padding__half {
            padding: 4px;
        }

        .its-padding {
            padding: 6px 6px 12px 6px;
        }

        div {
            font-size: 13px;
            line-height: 16px;
        }

        .its-searchbar,
        .its-icon--small {
            display: none;
        }
    }
}

.its-comments-listing--footer {
    @media print {
        display: none;
    }

    .its-pager {
        margin: 0 !important;
    }
}
