.its-kpi-key-drivers {
    md-tabs {
        background-color: $White;
    }

    .its-kpi-key-drivers--table {
        .its-kpi-key-drivers--table--progress-bar {
            width: 100%;
            padding-right: 40px;

            .its-kpi-key-drivers--progress-bar--fill {
                max-width: 100%;
                height: 10px;
                background-color: $PrimaryColour;
            }
        }

        .its-kpi-key-drivers--table--cell-width-60 {
            min-width: 60px;
            max-width: 60px;
            width: 60px;
        }

        .its-kpi-key-drivers--table--cell-width-80 {
            min-width: 80px;
            max-width: 80px;
            width: 80px;
        }

        .its-kpi-key-drivers--table-importance-indicator {
            min-height: 20px;
            max-width: 20px;
            border-radius: 20px;
        }
    }

    .kpi-change-positive {
        color: $PositiveColour;

        md-icon {
            color: $PositiveColour;
        }
    }

    .kpi-change-negative {
        color: $DangerColour;

        md-icon {
            color: $DangerColour;
        }
    }

    .its-kpi-key-drivers--content {
        .its-kpi-key-drivers--card-container {
            @extend .md-whiteframe-1dp;
            margin: 10px;
            background-color: #fff;

            .its-kpi-key-drivers--empty-state {
                padding-bottom: 50px;
            }
        }

        .its-kpi-key-drivers--card {
            padding: 12px;
        }

        .its-kpi-key-drivers--content--footer {
            border-top: solid 1px $BorderColour;
            background-color: $White;
            padding: 20px 20px 20px 15px;
        }
    }

    .its-kpi-key-drivers--current-value {
        margin-right: 20px;
        margin-left: 20px;
    }

    .its-kpi-key-drivers--segment-value {
        margin-right: 40px;
    }

    .its-kpi-key-drivers--value-large {
        @extend .its-text--headline;
    }

    .iti-kpi-predict--key-drivers-message {
        margin: 10px 10px 10px 15px;

        span {
            opacity: 0.7;
        }
    }

    .its-kpi-key-drivers--content {
        padding: 0;

        .no-padding {
            padding: 0 !important;
        }
    }

    .its-kpi-key-drivers--card-no-padding {
        background-color: $White;
        margin: 20px;
        padding: 0;
    }

    .its-kpi-key-drivers--card {
        background-color: $White;

        .its-kpi-key-drivers--card--info-text {
            @extend .its-text__muted;
            margin-left: 15px;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .its-kpi-key-drivers--card--empty-state-form {
            md-input-container {
                margin-left: 10px;
                margin-right: 30px;
            }
        }

        .its-kpi-key-drivers--card--empty-state-create-button {
            margin-left: 10px;
            margin-bottom: 30px;
        }

        .its-kpi-key-drivers--card--loading-text {
            @extend .its-text__muted;
            text-align: center;
            margin-top: 30px;

            .its-kpi-key-drivers--refresh-text {
                color: $PrimaryColour;
            }
        }

        .its-datalist--container {
            margin-bottom: 50px;
        }

        &.medium-height {
            min-height: 400px;
        }

        .its-kpi-key-drivers--card-header {
            position: relative;

            .its-kpi-key-drivers--card--heading {
                max-width: 75%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 15px;
            }

            .its-kpi-key-drivers--card--sub-heading {
                @extend .its-text__muted;
            }
        }

        .its-kpi-key-drivers--card-footer {
            padding-left: 10px;
            padding-right: 20px;
            min-height: 40px;

            .its-kpi-key-drivers--card-footer--info {
                @extend .its-text__muted;
                text-align: right;
            }
        }

        .its-kpi-key-drivers--card--question-name {
            @extend .its-text__muted;
            max-width: 605px;
            margin-top: 5px;
        }

        .its-kpi-key-drivers--card--program-name {
            margin-top: 20px;
        }

        .its-kpi-key-drivers--card-stats {
            .its-kpi-key-drivers--card-stats--stat {
                min-width: 100px;
                margin-right: 20px;
            }
        }

        .its-kpi-key-drivers--card--data-list {
            margin-left: 20px;
            margin-right: 20px;
            height: 40px;
        }
    }
}

.its-kpi-modal--large-icon {
    @include md-icon-size(50px);
    display: flex;
}

.its-kpi-modal--icon-container {
    height: 50px;
}

.its-kpi-modal--info-text {
    @extend .its-text__muted;
    margin: 0 10px;
}

.its-kpi-modal--info-container {
    background: $LightGrey;
    border: 1px solid $BorderColour;
    border-radius: 5px;
    padding: 15px;
    margin: 6px;
}

.its-kpi-key-drivers--key-driver-question--progress-bar {
    width: 100%;

    .its-kpi-key-drivers--key-driver-question--progress-bar--fill {
        height: 10px;
        background-color: $PrimaryColour;
    }
}
