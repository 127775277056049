.its-iql-global-filters {
    &.its-iql-global-filters--sub-nav {
        border-right: 1px solid #ddd;
        padding-right: 5px;
        margin-right: 5px;
    }

    .its-iql-global-filters--chips-container {
        > .layout-row {
            flex-direction: row-reverse;
        }
    }

    .its-iql-global-filters--chip {
        margin: 5px;
        border-radius: 15px;
        padding: 2px 10px 2px 10px;
        max-width: 280px;
        background-color: #d2d2d2;
        height: 28px;

        &.is-valid {
            color: white;
            background-color: rgb(0, 163, 224);

            .its-iql-global-filters--edit-button {
                color: white;
            }

            .its-iql-global-filters--clear-button {
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .its-iql-global-filters--chip-text--label {
            max-width: 195px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;

            &:hover {
                cursor: pointer;
                //font-weight: 500;
            }
        }

        .its-iql-global-filters----chip-text-label-appendix {
            max-width: 30px;
            white-space: nowrap;
        }

        .its-iql-global-filters--clear-button {
            margin-left: -6px;
            margin-right: 2px;
            padding-left: 3px;
            padding-right: 3px;
            font-size: 1.4em;
            padding-top: 3px;
            transition: color 50ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;

            &:hover {
                color: rgba(0, 0, 0, 1);
            }
        }
    }

    .its-iql-global-filters--edit-button {
    }

    .its-iql-global-filters--text-button {
        .its-iql-global-filters--action-icon {
            color: #2c2c2c;
        }

        ite-filter-panel-access-button {
            md-icon {
                color: rgba(0, 0, 0, 1);
            }
        }
    }
}

.its-iql-global-filters-panel {
    @extend .its-iql-global-filters;

    .its-filter-panel--content {
        padding-right: 10px;
    }

    .its-iql-global-filters--chip {
        font-size: 1em;
        max-width: 100%;
        width: 100%;
    }
}
