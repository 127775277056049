.its-hierarchy-panel {
    md-sidenav {
        background-color: transparent;
    }

    .its-hierarchy-panel--content {
        background-color: #ffffff;
        margin-top: 12px;

        .its-hierarchy-panel--content--container {
            overflow-y: auto !important;
        }
    }

    .its-hierarchy-panel--list-item {
        .its-hierarchy-panel--no_animate {
            .ng-leave {
                display: none;
            }
        }

        .its-hierarchy-panel--list-item-content {
            background-color: inherit;

            span {
                cursor: pointer;
            }

            .its-hierarchy-panel--expand-icon {
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    .its-hierarchy-panel--list-item-pad-left {
        padding-left: 28px;
    }

    .its-hierarchy-panel--list-item-content-tags {
        padding-left: 20px;
    }

    .iti-hierarchy-select-panel--items {
        padding-left: 20px;

        .its-accordion .its-accordion-list-item .its-accordion-list-item--label {
            color: #000;

            .its-accordion-list-item--label--text {
                font-weight: 400;
            }
        }

        .its-accordion .its-accordion-list-item .its-accordion-list-item--label .its-accordion-list-item--label--text {
            font-weight: 400;
        }
        .its-accordion .its-accordion-list-item .its-accordion-list-item--children {
            border-left: none;
        }
    }
}

.search {
    .its-accordion .its-accordion-list-item .its-accordion-list-item--icon-placeholder {
        display: none;
    }
}
